import { Box, Button, Checkbox, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { LuIndianRupee } from 'react-icons/lu';
import { MdOutlinePercent } from 'react-icons/md';
import { CustomChip } from '../../../../Components/CustomChip';
import LoadingDialog from '../../../../Components/Dialogs/LoadingDialog';
import { OutlinedTextField, CustomFormLabel, ControlledCustomSelect } from '../../../../Components/formComponents';
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../redux';
import { clearSingleProductModifierStateSync, defaultProductIngredient, defaultProductModifier, fetchSingleModifiersAsync, upsertSingleProductModifierAsync } from '../../../../redux/inventory';
import { CustomAccordion } from '../../../../Components/CustomAccordion/CustomAccordion';
import { ProductIngredientsTable } from '../../Products/ProductIngredients/ProductIngredientsTable';

interface IManageSingleModifierProps {
    customizationUUID: string
    modifierUUID: string | null;
    onCancel: () => void
}
export const ManageSingleModifier: React.FC<IManageSingleModifierProps> = ({
    customizationUUID,
    modifierUUID,
    onCancel
}) => {
    const [saveLoading, setSaveLoading] = React.useState(false);

    const dispatch = useAppDispatch();
    const {
        data: single_product_modifier,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.inventory.customization.single_product_modifier);

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: {
            ...defaultProductModifier,
            customization_uuid: customizationUUID,
        },
        validate: (values) => {
            const errors: any = {};
            if (!values.modifier_name) {
                errors.modifier_name = "*This field is required.";
            }
            if (!values.modifier_price) {
                errors.modifier_price = "*This field is required.";
            } else if (Number(values.modifier_price) <= 0) {
                errors.modifier_price = "*Price must be grater tha 0.";
            }
            return errors;
        },
        onSubmit: async (values) => {
            setSaveLoading(true);
            dispatch(
                upsertSingleProductModifierAsync(values)
            ).then(() => {
                onCancel()
            }).finally(() => {
                setSaveLoading(false);
            });
        },
    });

    useEffect(() => {
        if (!modifierUUID) return
        dispatch(fetchSingleModifiersAsync(modifierUUID))
    }, [modifierUUID])

    useEffect(() => {
        setValues({
            ...single_product_modifier,
            customization_uuid: customizationUUID,
            modifier_ingredients: single_product_modifier.modifier_ingredients.length > 0 ? single_product_modifier.modifier_ingredients : [defaultProductIngredient]
        })
    }, [single_product_modifier, customizationUUID])

    // useEffect(() => {
    //     if (!customizationUUID) return
    //     setValues({
    //         ...values,
    //         customization_uuid: customizationUUID,
    //     })
    // }, [customizationUUID])

    React.useEffect(() => {
        return () => {
            dispatch(clearSingleProductModifierStateSync());
        };
    }, []);

    console.log("ManageSingleModifier values =>", values,customizationUUID)
    return (
        <>
            <Box component="form"
                sx={{
                    height: "98vh",
                    overflowY: "scroll",
                    paddingRight: "16px",
                    marginTop: 4
                }}
                onSubmit={handleSubmit}
            >
                <CustomAccordion
                    title="Modifier Info"
                    expanded
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <OutlinedTextField
                                label="Name"
                                id="modifier_name"
                                placeholder="Enter Name"
                                value={values.modifier_name}
                                onChange={handleChange}
                                error={errors.modifier_name}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <OutlinedTextField
                                label="Type"
                                id="modifier_type"
                                placeholder="Enter Name"
                                value={values.modifier_type}
                                onChange={handleChange}
                                error={errors.modifier_type}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} />
                        <Grid item xs={12} sm={12} md={3}>
                            <Stack direction="row" alignItems="center">
                                <Checkbox checked={values.is_out_of_stock} onChange={(evt) => {
                                    setValues({
                                        ...values,
                                        is_out_of_stock: evt.target.checked,
                                        out_of_stock_for: null
                                    })
                                }} />
                                <Typography variant="body1" >Sold Out</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <CustomFormLabel>Price By</CustomFormLabel>
                            <Stack direction="row" spacing={2}>
                                <CustomChip
                                    label="Each"
                                    sx={{ p: 3, fontSize: "16px" }}
                                    color={values.modifier_price_by === "EACH" ? "warning" : "default"}
                                    onClick={() => setFieldValue("modifier_price_by", "EACH")}
                                />
                                <CustomChip label="Weight"
                                    sx={{ p: 3, fontSize: "16px" }}
                                    color={values.modifier_price_by === "WEIGHT" ? "warning" : "default"}
                                    onClick={() => setFieldValue("modifier_price_by", "WEIGHT")}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={3}>
                            <OutlinedTextField
                                label="Price"
                                id="modifier_price"
                                placeholder="Enter Price"
                                value={values.modifier_price}
                                onChange={handleChange}
                                error={errors.modifier_price}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LuIndianRupee fontSize={"24px"} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        {values.modifier_price_by === "WEIGHT" &&
                            <Grid item xs={12} sm={12} md={3}>
                                <ControlledCustomSelect
                                    label="Weight Unit"
                                    name="product_weight_unit"
                                    fullWidth
                                    options={[
                                        { value: "lb", label: "lb" },
                                        { value: "oz", label: "oz" },
                                        { value: "kg", label: "kg" },
                                        { value: "g", label: "g" },
                                    ]}
                                    placeholder="Select Weight Unit"
                                    value={values.modifier_weight_unit}
                                    onChange={handleChange}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} sm={12} md={3}>
                            <OutlinedTextField
                                label="Tax Rate"
                                id="modifier_tax"
                                placeholder="Enter Tax Rate"
                                value={values.modifier_tax}
                                onChange={handleChange}
                                // error={errors.product_tax}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <MdOutlinePercent fontSize={"24px"} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>



                        {/* If out of stock is checked, then only this field will be visible */}
                        {values.is_out_of_stock && <Grid item xs={12} sm={12} md={3}>
                            <ControlledCustomSelect
                                label="Out of stock For"
                                name="out_of_stock_for"
                                options={[
                                    { value: "Today", label: "Today" },
                                    { value: "Indefinitely", label: "Indefinitely" },
                                ]}
                                clearable
                                value={values.out_of_stock_for}
                                onChange={handleChange}
                            />
                        </Grid>}
                    </Grid>
                </CustomAccordion>

                <CustomAccordion title="Ingredient Details">
                    <ProductIngredientsTable
                        data={values.modifier_ingredients || []}
                        onChange={(data) => setFieldValue("modifier_ingredients", data)} />
                </CustomAccordion>

                <CustomAccordion title="Energy Values">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                            <OutlinedTextField
                                label="Calories"
                                name="calories"
                                type="number"
                                placeholder="Enter item's calorie count"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Typography variant="h6">Cal</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                                value={values.calories}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <OutlinedTextField
                                label="Kilojoules"
                                name="kilojoules"
                                type="number"
                                placeholder="Enter item's kilojoule count"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Typography variant="h6">KJ</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                                value={values.kilojoules}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </CustomAccordion>

                <CustomAccordion title='Extra Details' >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                            <OutlinedTextField
                                label="GST IN"
                                id="gst_number"
                                placeholder="enter gst number"
                                value={values.gst_number}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <OutlinedTextField
                                id="external_id"
                                label="External ID"
                                placeholder="enter external id"
                                value={values.external_id}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <OutlinedTextField
                                label="External Data"
                                id="external_data"
                                placeholder="enter external data"
                                value={values.external_data}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </CustomAccordion>

                {/* <CustomDivider sx={{ mb: 2, mt: 4 }} borderColor='#dadada' /> */}


                <Stack marginBlock={2} direction="row" justifyContent="flex-end" spacing={2} >
                    <Button sx={{ minWidth: 120 }} variant="contained" onClick={onCancel}>Cancel</Button>
                    <Button sx={{ minWidth: 120 }} variant="contained" type="submit">Save</Button>
                </Stack>
            </Box >
            {saveLoading && <LoadingDialog open={saveLoading} />
            }
        </>
    )
}
