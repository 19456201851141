import React from 'react'
import './ReceiptOutput.css'
import { IReceiptComposer } from '../../../../redux/settings/receipt-composer'
import moment from 'moment'

export const ReceiptOutput: React.FC<{ data: IReceiptComposer, file: File | null }> = ({
    data, file
}) => {

    return (
        <div className='output-body' style={{
            background: "#fff",
            color: "#000",
            paddingTop: data.padding_top,
            paddingBottom: data.padding_bottom,
            paddingLeft: data.padding_left,
            paddingRight: data.padding_right,
            
        }}>

            {(file || data.receipt_logo) && <div style={{ overflow: "hidden" }}>

                <div className="text-center space-bottom">
                    {/* <!-- <img src='https://i.postimg.cc/8CSyK3TW/Untitled-design-2.png' alt='Untitled-design-2' /> --> */}
                    <img src={file ? URL.createObjectURL(file) : data.receipt_logo  ? `${process.env.REACT_APP_S3_PUBLIC_PREFIX_URL}${data.receipt_logo }` : ""} alt='Untitled-design-2' style={{ width: 100, height: 100, borderRadius: 50 }} />
                </div>

            </div>}
            <div className="footer">
                <div dangerouslySetInnerHTML={{ __html: data.receipt_header }}></div>
            </div>

            {data.order_details.filter((column) => column.column_title.length > 0).length > 0 && <table>
                <th style={{ width: "100%" }}>
                    <tr>
                        {data.order_details.filter((column) => column.column_title.length > 0).map((column, idx) => {
                            return <td style={{ width: `${column.column_width}%` }}>{column.column_title}</td>
                        })}
                    </tr>
                </th>
                <tbody>
                    <tr>
                        {data.order_details.filter((column) => column.column_title.length > 0).map((column, idx) => {
                            return <td style={{ width: `${column.column_width}%` }}>{column.column_value}</td>
                        })}
                    </tr>
                </tbody>
            </table>
            }
            <div className="footer">
                <div dangerouslySetInnerHTML={{ __html: data.receipt_footer }}></div>
            </div>

        </div>
    )
}
