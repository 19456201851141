import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICommonState } from "./common.types";
import { defaultCommonState } from "./common.state";



const commonSlice = createSlice({
    initialState: defaultCommonState,
    name: "common",
    reducers: {
        displayErrorDialog: (state, action: PayloadAction<ICommonState["errorVisible"]>) => {
            state.errorVisible = action.payload
        },
        closeErrorDialog: (state) => {
            state.errorVisible = null
        },
        displaySnackBarDialog: (state, action: PayloadAction<ICommonState["snackbarVisible"]>) => {
            state.snackbarVisible = action.payload
        },
        closeSnackBarDialog: (state) => {
            state.snackbarVisible = null
        },
        openLoaderWithMessage: (state, action: PayloadAction<ICommonState["loader_with_message"]["message"]>) => {
            state.loader_with_message.loading = true
            state.loader_with_message.message = action.payload
        },
        closeLoaderWithMessage: (state) => {
            state.loader_with_message.loading = false
            state.loader_with_message.message = defaultCommonState["loader_with_message"]["message"]
        },
    },
});

export const commonReducer = commonSlice.reducer;
export const {
    displayErrorDialog,
    closeErrorDialog,
    displaySnackBarDialog,
    closeSnackBarDialog,
    openLoaderWithMessage,
    closeLoaderWithMessage
} = commonSlice.actions;