import React from 'react'
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../../../redux";
import { Dialog } from "../../../../../../Components/Dialogs/Dialog";
import { Typography, Box, Stack } from "@mui/material";
import { StandardCard } from "../../../../../../Components/Card";
import { CustomRoundButton } from "../../../../../../Components/CustomButton";
import { defaultPaymentReceived, upsertSinglePaymentReceivedAsync } from "../../../../../../redux/accounting/payment-received";
import RssFeedIcon from '@mui/icons-material/RssFeed';
import { IOrder } from "../../../../../../redux/orders";


export interface ICardPaymentScreenProps {
    orderInfo: IOrder;
    onClose: () => void;
}

export const CardPaymentScreen: React.FC<ICardPaymentScreenProps> = ({orderInfo, onClose}) => {

    const dispatch = useAppDispatch();
    const [saveLoading, setSaveLoading] = React.useState(false);

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues
    } = useFormik({
        initialValues: {
            ...defaultPaymentReceived,
            order_type: orderInfo.order_type,
            order_number: "",
            invoice_number: "",
            total_tax: orderInfo.total_tax.toString(),
            total_amount: orderInfo.total_amount.toString(),
            order_details: orderInfo.menu_items,
            payment_mode: orderInfo.payment_mode,
        },
        validate: (values) => {
            const errors: any = {};
            // if (!values.category_name) {
            //     errors.category_name = "*This field is required.";
            // }
            return errors;
        },
        onSubmit: async (values) => {
            setSaveLoading(true);
            dispatch(
                upsertSinglePaymentReceivedAsync(values)
            ).then((res => {
                if (res.meta.requestStatus === "fulfilled") {
                    // navigate()
                }
            })).finally(() => {
                setSaveLoading(false);
                onClose()
            });
        },
    });


  return (
    <StandardCard
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    paddingBottom: 2,
                    minHeight: 550
                }}
            >
                {/* <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                    <Typography variant="h4" fontWeight={"700"}>
                        {`Total Amount : ${values.total_amount}`}
                    </Typography>
                </Stack> */}
              
                <Stack direction="column" width="100%" justifyContent="center" alignItems="center" spacing={2}>
                   <Box sx={{
                    height: 100,
                    width: 100,
                    borderRadius: 50,
                    border: "1px solid #fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                   }}><RssFeedIcon sx={{fontSize: 40, transform: "rotate(45deg)"}} /></Box>
                    <Typography variant="h5" fontWeight={"600"}>
                        Hold your card near reader
                    </Typography>
                </Stack>


                <Stack direction="row" width="100%" spacing={2} px={2}>
                    <CustomRoundButton
                        title="Cancel"
                        type="submit"
                        // disabled={Number(values.cash_received) < Number(values.total_amount)}
                        onClick={onClose}
                        fullWidth />
                </Stack>
            </StandardCard>
  )
}
