import React, { useState } from 'react'
import { IOrder } from '../../../../../../redux/orders';
import { Dialog } from '../../../../../../Components/Dialogs/Dialog';
import { DialogTitle, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CashPaymentScreen } from '../OrderPaymentDialog/CashPaymentScreen';
import { BiSolidDollarCircle } from 'react-icons/bi';
import { FaCreditCard, FaMoneyBill } from 'react-icons/fa';
import { DiscountCouponsScreen } from './DiscountCouponsScreen';
import { OtherChargesScreen } from './OtherChargesScreen';

const orderPaymentType = [
  { title: "Charges", value: "CHARGES", icon: <BiSolidDollarCircle fontSize={"2rem"} /> },
  { title: "Coupon", value: "COUPON", icon: <FaMoneyBill fontSize={"2rem"} /> },
]



export interface IExtraChargesDialogProps {
  open: boolean;
  orderInfo: IOrder;
  onClose: () => void;
}

export const ExtraChargesDialog: React.FC<IExtraChargesDialogProps> = ({
  open,
  orderInfo,
  onClose
}) => {
  const [orderPaymentMode, setOrderPaymentMode] = useState<"CHARGES" | "COUPON">("CHARGES")



  return (
    <Dialog
      open={open}
      title="Coupon & Charges"
      size="sm"
      // contentWrappedWithForm={{ onSubmit: handleSubmit }}
      onClose={onClose}
      contentSx={{
        padding: 0,
        minHeight: 550,
        background: "#171717"
      }}
      hideHeader
    >
      <DialogTitle fontSize={18} fontWeight={700} sx={{ m: 0, p: 2, background: "#0f0f0f" }} id="customized-dialog-title">
        Coupon & Charges
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <Grid container >
        <Grid item xs={12} md={3} sx={{ borderRight: "1px solid #606060", minHeight: 550 }}>
          <List sx={{ padding: 0 }}>
            {orderPaymentType.map((l_item, l_idx) => {
              return (
                <ListItem disablePadding sx={{ background: l_item.value === orderPaymentMode ? "#606060" : "#303030", mb: .25 }} onClick={() => setOrderPaymentMode(l_item.value as "CHARGES")}>
                  <ListItemButton>
                    <ListItemIcon>
                      {l_item.icon}
                    </ListItemIcon>
                    <ListItemText primary={l_item.title} />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        </Grid>
        <Grid item xs={12} md={9}>
          {orderPaymentMode === "CHARGES" ?
            <OtherChargesScreen />
            :
            <DiscountCouponsScreen />
          }

        </Grid>
      </Grid>
    </Dialog>
  )
}
