import type { IDateValue } from '../types/common';
import type { CardProps } from '@mui/material/Card';
import type { TableHeadCustomProps } from '../components/table';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import ListItemText from '@mui/material/ListItemText';
import Badge, { badgeClasses } from '@mui/material/Badge';

import { fCurrency } from '../utils/format-number';
import { fDate, fTime } from '../utils/format-time';

import { Label } from '../components/label';
import { Iconify } from '../components/iconify';
import { Scrollbar } from '../components/scrollbar';
import { TableHeadCustom } from '../components/table';
import { usePopover, CustomPopover } from '../components/custom-popover';
import React from 'react';
import { IStoreState, useAppDispatch, useAppSelector } from '../../../redux';
import { useNavigate } from 'react-router-dom';
import { clearPaymentReceivedStateSync, clearPaymentsPaidState, fetchPaymentPaidListWithArgsAsync, IPaymentPaid, IPaymentReceived } from '../../../redux/accounting';

// ----------------------------------------------------------------------

type Props = CardProps & {
  title?: string;
  subheader?: string;
  headLabel: TableHeadCustomProps['headLabel'];
  tableData: {
    id: string;
    type: string;
    status: string;
    amount: number;
    message: string;
    date: IDateValue;
    category: string;
    name: string | null;
    avatarUrl: string | null;
  }[];
};

export function BankingRecentTransitions({
  title,
  subheader,
  tableData,
  headLabel,
  ...other
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    data: payments_paid_list,
    loading,
  } = useAppSelector((storeState: IStoreState) => storeState.accounting.paymentPaid.payments_paid_list);



  React.useEffect(() => {
    dispatch(
      fetchPaymentPaidListWithArgsAsync({
        page: 1,
        rowsPerPage: 10,
      }),
    );

    return () => {
      dispatch(clearPaymentsPaidState());
    };
  }, []);

  const handleOnViewAllUsersClick = () => {
    navigate(`/accounting/payment-paid`)
  }

  return (
    <Card {...other}>
      <CardHeader title={title}
        titleTypographyProps={{
          variant: "h3"
        }}
        subheader={subheader} sx={{ mb: 3 }}
        action={
          <Button
            size="small"
            color="inherit"
            onClick={handleOnViewAllUsersClick}
            endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
          >
            View all
          </Button>
        }
      />

      <Scrollbar sx={{ minHeight: 462 }}>
        <Table sx={{ minWidth: 720 }}>
          <TableHeadCustom headLabel={headLabel} />

          <TableBody>
            {payments_paid_list.map((row) => (
              <RowItem key={row.payment_paid_uuid} row={row} />
            ))}
          </TableBody>
        </Table>
      </Scrollbar>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          size="small"
          color="inherit"
          endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
        >
          View all
        </Button>
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

type RowItemProps = {
  row: IPaymentPaid;
};

function RowItem({ row }: RowItemProps) {
  const theme = useTheme();

  const popover = usePopover();

  const lightMode = theme.palette.mode === 'light';

  const handleDownload = () => {
    popover.onClose();
    console.info('DOWNLOAD', row.payment_paid_uuid);
  };

  const handlePrint = () => {
    popover.onClose();
    console.info('PRINT', row.payment_paid_uuid);
  };

  const handleShare = () => {
    popover.onClose();
    console.info('SHARE', row.payment_paid_uuid);
  };

  const handleDelete = () => {
    popover.onClose();
    console.info('DELETE', row.payment_paid_uuid);
  };

  const renderAvatar = (
    <Box sx={{ position: 'relative' }}>
      <Badge
        overlap="circular"
        color={row.order_status === 'PAID' ? 'success' : 'error'}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Iconify
            icon={
              row.order_status === 'PAID'
                ? 'eva:diagonal-arrow-left-down-fill'
                : 'eva:diagonal-arrow-right-up-fill'
            }
            width={16}
          />
        }
        sx={{ [`& .${badgeClasses.badge}`]: { p: 0, width: 20 } }}
      >
        <Avatar
          src={row.attachment || ''}
          sx={{
            width: 48,
            height: 48,
            color: 'text.secondary',
            bgcolor: 'background.neutral',
          }}
        >
          {row.category === 'Fast food' && <Iconify icon="ion:fast-food" width={24} />}
          {row.category === 'Fitness' && <Iconify icon="ic:round-fitness-center" width={24} />}
        </Avatar>
      </Badge>
    </Box>
  );

  return (
    <>
      <TableRow>
        <TableCell>
          {/* <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
             {renderAvatar} 
          </Box>
           */}
            <ListItemText
            primary={row.vendor}
            secondary={row.category}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ mt: 0.5, component: 'span', typography: 'caption' }}
          />
        </TableCell>

        <TableCell>
          <ListItemText
            primary={fDate(row.create_ts)}
            secondary={fTime(row.create_ts)}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ mt: 0.5, component: 'span', typography: 'caption' }}
          />
        </TableCell>

        <TableCell>{row.total_amount}</TableCell>
        {/* <TableCell>{fCurrency(row.total_amount)}</TableCell> */}

        <TableCell>
          <Label
            variant={lightMode ? 'soft' : 'filled'}
            color={
              (row.order_status === 'PAID' && 'success') ||
              (row.order_status === 'UNPAID' && 'warning') ||
              'error'
            }
            sx={{ textTransform: 'capitalize' }}
          >
            {row.order_status}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ pr: 1 }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{ arrow: { placement: 'right-top' } }}
      >
        <MenuList>
          <MenuItem onClick={handleDownload}>
            <Iconify icon="eva:cloud-download-fill" />
            Download
          </MenuItem>

          <MenuItem onClick={handlePrint}>
            <Iconify icon="solar:printer-minimalistic-bold" />
            Print
          </MenuItem>

          <MenuItem onClick={handleShare}>
            <Iconify icon="solar:share-bold" />
            Share
          </MenuItem>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
          </MenuItem>
        </MenuList>
      </CustomPopover>
    </>
  );
}
