import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { defaultUserProfileState } from "./user-profile.state";
import { fetchAllUsersProfileAsync, fetchSingleUserProfileAsync } from "./user-profile.actions";
import { LoadState } from "../../../constants/enum";
import { IUserProfile } from "./user-profile.types";

const userProfileSlice = createSlice({
    initialState: defaultUserProfileState,
    name: "user-profile",
    reducers: {
        clearAllUsersStateSync: (state) => {
            state.users_list.data = defaultUserProfileState["users_list"]["data"]
        },
        clearSingleUserStateSync: (state) => {
            state.single_user.data = defaultUserProfileState["single_user"]["data"]
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllUsersProfileAsync.pending, (state, action) => {
            state.users_list.loading = LoadState.pending
        })
        builder.addCase(fetchAllUsersProfileAsync.fulfilled, (state, action: PayloadAction<{ data: IUserProfile[], totalRecords: number }>) => {
            state.users_list.loading = LoadState.succeeded
            state.users_list.data = action.payload.data
            state.users_list.totalRecords = action.payload.totalRecords
            state.users_list.error = null
        })
        builder.addCase(fetchAllUsersProfileAsync.rejected, (state, action) => {
            state.users_list.error = action.error.message as string
        })




        builder.addCase(fetchSingleUserProfileAsync.pending, (state, action) => {
            state.single_user.loading = LoadState.pending
        })
        builder.addCase(fetchSingleUserProfileAsync.fulfilled, (state, action) => {
            state.single_user.loading = LoadState.succeeded
            state.single_user.data = action.payload
            state.single_user.error = null
        })
        builder.addCase(fetchSingleUserProfileAsync.rejected, (state, action) => {
            state.single_user.error = action.error.message as string
        })
    },
});

export const userProfileReducer = userProfileSlice.reducer;
export const { clearAllUsersStateSync, clearSingleUserStateSync } = userProfileSlice.actions;
