import {
  NodeResizer,
  type NodeProps,
  useStore,
  useKeyPress,
  useReactFlow,
  useUpdateNodeInternals,
} from 'reactflow';

import Shape from '../shape';
import NodeLabel from './label';
import { getRandomColorFromStringArray } from '../../../../helpers/getRandomNumber';
import { useNavigate } from 'react-router-dom';
import { tableAvailability } from '../../../../views/Tables/components/StandardTableCard/StandardTableCard.types';
import { TablesReserveChip } from '../../../CustomChip';
import { useEffect, useRef, useState } from 'react';
import { drag } from 'd3-drag';
import { select } from 'd3-selection';


import './shape-node.styles.css';
import { RightToolbar, TopToolbar } from '../toolbar';
import { ICustomTableNode, ICustomTableNodeData } from '../../ReactFlowShapes.types';
import { POS_APP_MENU_ROUTE_NEW_ORDER } from '../../../../views/MenuAndOrders/MenuAndOrders.constants';
import { Box } from '@mui/material';
import { useAppDispatch } from '../../../../redux';
import { createDineInOrderFromTableSync, defaultOrder, upsertSingleOrderWithCallbackAsync } from '../../../../redux/orders';

// export type ShapeNodeData = {
//   type: ShapeType;
//   color: string;
//   title: string;
//   mode: "editable" | "viewOnly"
//   availability: "FREE" | "RESERVED" | "CHECKED_IN"
//   nodeType: "TABLE" | "CUSTOM"
//   tableNumber: number
//   disabled?: boolean
// };

// this will return the current dimensions of the node (measured internally by react flow)
function useNodeDimensions(id: string) {
  const node = useStore((state) => state.nodeInternals.get(id));
  return {
    width: node?.width || 0,
    height: node?.height || 0,
  };
}

function ShapeNode(props: NodeProps<ICustomTableNodeData>) {
  const { id, selected, data } = props;
  const { color, type, title, mode, disabled } = data;
  const { setNodes } = useReactFlow();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const rotateControlRef = useRef(null);
  const updateNodeInternals = useUpdateNodeInternals();
  const [rotation, setRotation] = useState(0);

  const { width, height } = useNodeDimensions(id);
  const shiftKeyPressed = useKeyPress('Shift');

  useEffect(() => {
    if (!rotateControlRef.current) {
      return;
    }

    const selection = select(rotateControlRef.current);
    const dragHandler = drag().on('drag', (evt) => {
      const dx = evt.x - 100;
      const dy = evt.y - 100;
      const rad = Math.atan2(dx, dy);
      const deg = rad * (180 / Math.PI);
      setRotation(180 - deg);
      updateNodeInternals(id);
    });

    selection.call(dragHandler as any);
  }, [id, updateNodeInternals]);

  const onColorChange = (color: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              color,
            },
          };
        }

        return node;
      })
    );
  };


  const onTitleChange = (newTitle: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              title: newTitle,
            },
          };
        }

        return node;
      })
    );
  };


  const onTableNumberChange = (newNumber: number) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              tableNumber: newNumber,
            },
          };
        }
        return node;
      })
    );
  };

  const onDeleteClick = () => {
    setNodes((nodes) =>
      nodes.map((node: ICustomTableNode) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              table_number: -1,
              status: "INACTIVE"
            }
          }
        }
        return node
      })
    );
  };


  const onDuplicateClick = () => {
    setNodes((nodes) => {
      const currentNode = nodes.find((node) => node.id === id)
      if (currentNode) {
        return nodes.map((n) => ({ ...n, selected: false })).concat({
          ...currentNode,
          id: Date.now().toString(),
          position: {
            ...currentNode.position,
            x: currentNode.position.x + 10
          },
          data: {
            ...currentNode.data,
            color: getRandomColorFromStringArray(currentNode.data.color),
            tableNumber: nodes.filter(table => table.data.nodeType === "TABLE").length + 1
          },
          selected: true
        })
      }
      return nodes
    });
  };


  const stanDardTable = () => (
    <>
      <Shape
        type={type}
        width={width}
        height={height}
        fill={disabled ? "#87878786" : color}
        strokeWidth={2}
        stroke={disabled ? "#87878786" : color}
        fillOpacity={0.8}
      />
      {/* <Typography variant="h5" textAlign="center" fontWeight={"700"}>
        {data.title}
      </Typography> */}
      <NodeLabel disabled placeholder={type} value={data.title} onChange={onTitleChange} />
      <TablesReserveChip classes={`node-chip ${type === "square" ? "node-chip-square-box" : type === "circle" ? "node-chip-circle" : ""} ${disabled ? "node-chip-disabled" : ""}`} label={tableAvailability[data.availability]} />
    </>
  )

  const onTableClick = () => {
    // dispatch(upsertSingleOrderWithCallbackAsync({
    //   payload: {
    //     ...defaultOrder,
    //     order_type: "DINE_IN",
    //     table_uuid: id,
    //     table_number: data.table_number,
    //   },
    //   onSuccess(isSuccess, resData) {
    //     if (isSuccess && resData) {
    //       navigate(`${POS_APP_MENU_ROUTE_NEW_ORDER}/edit/${resData.order_uuid}`)
    //     }
    //   },

    // }))
    dispatch(createDineInOrderFromTableSync({
      ...defaultOrder,
      order_type: "DINE_IN",
      table_uuid: id,
      table_number: data.table_number,
    }))
    navigate(`${POS_APP_MENU_ROUTE_NEW_ORDER}?type=DINE_IN`)
  }

  if (mode === "viewOnly") return (
    type === "line" ?
      <Shape
        type={type}
        width={width}
        height={height}
      />
      : disabled ? stanDardTable()
        :
        <Box onClick={onTableClick} sx={{ cursor: "pointer" }}>
          {stanDardTable()}
        </Box>
  )

  return (
    <div
      style={{
        transform: `rotate(${rotation}deg)`,
      }}
      className=".shape-node"
    >
      <TopToolbar
        onColorChange={onColorChange}
        activeColor={color}
        onDelete={onDeleteClick}
        onDuplicate={onDuplicateClick}

      />
      {type !== "line" && <RightToolbar
        title={title}
        tableNumber={data.table_number}
        onTitleChange={onTitleChange}
        onTableNumberChange={onTableNumberChange}

      />}
      <NodeResizer
        color={color}
        keepAspectRatio={shiftKeyPressed}
        isVisible={selected}

      />
      <div
        ref={rotateControlRef}
        style={{
          display: "block",
        }}
        className={`nodrag .rotateHandle`}
      />
      <Shape
        type={type}
        width={width}
        height={height}
        fill={color}
        strokeWidth={2}
        stroke={color}
        fillOpacity={0.8}
      />
      {/* <Handle
        style={{ opacity: 0 }}
        position={Position.Left}
        type="source"
      />
      <Handle
        style={{ opacity: 0 }}
        position={Position.Right}
        type="target"
      /> */}
      {/* <Handle
        style={handleStyle}
        id='top'
        type='source'
        position={Position.Top}
      />
      <Handle
        style={handleStyle}
        id='right'
        type='source'
        position={Position.Right}
      />
      <Handle
        style={handleStyle}
        id='bottom'
        type='source'
        position={Position.Bottom}
      />
      <Handle
        style={handleStyle}
        id='left'
        type='source'
        position={Position.Left}
      /> */}
      {type !== "line" && <NodeLabel placeholder={type} value={title} onChange={onTitleChange} />}
    </div>
  );
}

export default ShapeNode;
