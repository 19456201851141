import React, { useEffect, useState } from 'react'
import { ExtendableSelectField, IExtendableSelectProps } from '../../../../Components/formComponents'
import { axios_api } from '../../../../api'

interface IPaymentPaidCategorySelectProps {
    value: string
    onChange: IExtendableSelectProps["onChange"]
    error?: string
}

export const PaymentPaidCategorySelect: React.FC<IPaymentPaidCategorySelectProps> = ({
    value,
    onChange,
    error
}) => {
    const [accountingVendors, setAccountingVendors] = useState<IExtendableSelectProps["options"]>([])

    useEffect(() => {
        axios_api.get(`/accounting/get-accounting-category`).then((res) => {
            const response = res.data.data as { category_uuid: string, category_name: string }[]
            const filterData: IExtendableSelectProps["options"] = response.map((vendor) => {
                return { label: vendor.category_name, value: vendor.category_name }
            })
            setAccountingVendors(filterData)
        })
    }, [])

    console.log("accountingVendors ===>", accountingVendors)
    return (
        <ExtendableSelectField
            label="Category"
            value={value}
            onChange={onChange}
            options={accountingVendors}
            error={error}
        />
    )
}
