import { ICommonState } from "./common.types";



export const defaultCommonState: ICommonState = {
    errorVisible: null,
    snackbarVisible: null,
    loader_with_message: {
        loading: false,
    },
}