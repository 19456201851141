import React from "react";
import { DataTable } from "../../../Components/Table/DataTable";
import { IDataTableProps, MobileLogoRenderType, RenderType } from "../../../Components/Table/interfaces/IDataTableProps";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { useNavigate } from "react-router-dom";
import { IProductCustomization, clearProductCustomizationState, clearProductCustomizationsListSync, defaultProduct, fetchCustomizationListWithArgsAsync } from "../../../redux/inventory";
import { StandardTableActions } from "../../../Components/StandardTableActions/StandardTableActions";
import { ManageCustomizationDialog } from "./dialogs/ManageCustomizationDialog";
import { CustomizationOptionRightPanel } from "./RightPanel/CustomizationOptionRightPanel";

export const CustomizationsTable = () => {
  const {
    data: customizationsList,
    loading,
  } = useAppSelector((storeState: IStoreState) => storeState.inventory.customization.customizations_list);

  const [pagination, setPagination] = React.useState({
    pageNumber: 1,
    rowsPerPage: 10,
  });


  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openUpsertDialog, setOpenUpsertDialog] = React.useState<IProductCustomization | null>(null);
  const [openCustomizationOptionsDialog, setpenCustomizationOptionsDialog] = React.useState<string | null>(null);

  const customizationTableProps: IDataTableProps = {
    isPagination: true,
    rowsPerPageOptions: 25,
    isDataLoading: false,
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },

    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IProductCustomization) => {
          return (
            <StandardTableActions
              onEditClick={() => setOpenUpsertDialog(row)}
            />
          );
        },
      },
      {
        key: "customization_name",
        headerName: "Customization Name",
        fieldName: "customization_name",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
        onRowCellRender: (value, row: IProductCustomization) => {
          return (
            <Typography variant="h6"fontWeight={"bold"} >
              {row.customization_name}
            </Typography>
          );
        },
      },
      {
        key: "customization_options",
        headerName: "Options",
        fieldName: "customization_options",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IProductCustomization) => {
          return (
            <Typography variant="h6"fontWeight={"bold"} >
              {row.customization_options}
            </Typography>
          );
        },
      },
      // {
      //   key: "status",
      //   headerName: "Notes",
      //   fieldName: "status",
      //   exportCellWidth: 25,
      //   renderType: RenderType.TEXT,
      // },
      // {
      //   key: "status",
      //   headerName: "Status",
      //   fieldName: "status",
      //   exportCellWidth: 25,
      //   renderType: RenderType.CUSTOM_RENDER,
      // },
      {
        key: "view",
        headerName: "View",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        headingAlign: "right",
        onRowCellRender: (value, row: IProductCustomization) => {
          return (
            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
            <Button variant="contained" onClick={() => setpenCustomizationOptionsDialog(row.customization_uuid)} >View Options</Button>
            </Box>
          );
        },
      },
    ],

    items: customizationsList,
    onPageChange: (newPageNumber: number) => {
      setPagination({ ...pagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setPagination({
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
      });
    },
  };


  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  React.useEffect(() => {
    return () => {
      dispatch(clearProductCustomizationsListSync());
    };
  }, []);

  const fetchList = () => {
    dispatch(
      fetchCustomizationListWithArgsAsync({
        page: pagination.pageNumber,
        rowsPerPage: pagination.rowsPerPage,
      }),
    );
  };

  return (
    <>
      <DataTable {...customizationTableProps} />
      {
        openUpsertDialog &&
        <ManageCustomizationDialog
          open={true}
          customization={openUpsertDialog}
          onClose={() => setOpenUpsertDialog(null)}
        />
      }
      {openCustomizationOptionsDialog && <CustomizationOptionRightPanel
        open={true}
        onClose={() => {
          setpenCustomizationOptionsDialog(null)
          fetchList()
        }}
        customizationUUID={openCustomizationOptionsDialog}
      />}

    </>
  )
};
