import { createAsyncThunk } from "@reduxjs/toolkit";
import { IQueryParams, getSearchQuery } from "../../../common";
import { axios_api } from "../../../api/axios-api";
import { IPaymentReceived } from "./payment-received.types";
import { closeLoaderWithMessage, openLoaderWithMessage } from "../../common";

const GET_ACCOUNTING_PAYMENTS_RECEIVED_ENDPOINT= "/accounting/get-accounting-payment-received"
const UPSERT_ACCOUNTING_PAYMENTS_RECEIVED_ENDPOINT = "/accounting/upsert-accounting-payment-received"

export const fetchPaymentReceivedListWithArgsAsync = createAsyncThunk<{ recordCount: number, data: IPaymentReceived[] }, IQueryParams>(
    'payment-received/fetchPaymentReceivedListWithArgsAsync', async (queryParams: IQueryParams, thunkAPI) => {
        thunkAPI.dispatch(openLoaderWithMessage("Fetching Payment Details . . . !"))
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_ACCOUNTING_PAYMENTS_RECEIVED_ENDPOINT}${searchQuery}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        thunkAPI.dispatch(closeLoaderWithMessage())
        return { recordCount: count, data: data }
    },
)


export const upsertSinglePaymentReceivedAsync = createAsyncThunk<IPaymentReceived, IPaymentReceived>(
    'product-categories/upsertSinglePaymentReceivedAsync', async (payload: IPaymentReceived, thunkAPI) => {
        thunkAPI.dispatch(openLoaderWithMessage("Saving Changes...!"))
        const response = await axios_api.post(UPSERT_ACCOUNTING_PAYMENTS_RECEIVED_ENDPOINT, payload)
        thunkAPI.dispatch(fetchPaymentReceivedListWithArgsAsync({
            page: 1,
            rowsPerPage: 10
        }))
        thunkAPI.dispatch(closeLoaderWithMessage())
        return response.data
    },
)