


export const months_list = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
]
export const getYearsList = () => {
    const yersList = []
    const currentYear = new Date().getFullYear()
    for (let i = currentYear - 4; i < currentYear + 2; i++) {
        yersList.push(i)
    }
    return yersList
}


export const groupDataByMonth = (objDataWithKeys: any) => {
    const keysArray = Object.keys(objDataWithKeys);
    return keysArray.reduce((acc, date) => {
        const [year, month] = date.split('-');
        const key = `${year}-${month}`;
        if (!(acc as any)[key]) {
            (acc as any)[key] = [];
        }
        (acc as any)[key].push(date);
        return acc;
    }, {});
}