import { CheckCircle } from '@mui/icons-material'
import ContentCopy from '@mui/icons-material/ContentCopy'
import { Box, Button, Chip } from '@mui/material'
import React, { useState } from 'react'
import { fetchColumnNamesForReceipt } from './OutletColumnsButton.helpers'

export const OutletColumnsButton = () => {

    const [columnList, setColumnList] = useState<string[]>([])
    const [isCopy, setIsCopy] = useState<number>(-1)

    const fetchColumnDetails = (key: "OUTLET" | "ORDER") => {
        fetchColumnNamesForReceipt(key).then(res => {
            setColumnList(res)
        })
    }

    const handleColumnClick = (text: string, idx: number) => {
        setIsCopy(idx)
        window.navigator.clipboard.writeText(`<%= ${text}%>`)
        setTimeout(() => {
            setIsCopy(-1)
        }, 2000)

    }

    return (
        <>
            <Box sx={{ display: "flex", alignItems: 'center', gap: 2, justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    onClick={() => fetchColumnDetails("OUTLET")}
                >
                    Outlet Details
                </Button>
                <Button
                    variant="contained"
                    onClick={() => fetchColumnDetails("ORDER")}
                >
                    Order Details
                </Button>
            </Box>
            {columnList.length > 0 &&
                <Box sx={{ display: "flex", alignItems: 'center', gap: 2, mt: 2, justifyContent: "flex-end", flexWrap: 'wrap' }}>
                    {columnList.map((column, idx) => {
                        return (
                            <Chip
                                label={column}
                                // onClick={handleClick}
                                onDelete={() => handleColumnClick(column, idx)}
                                deleteIcon={isCopy === idx ? <CheckCircle /> : <ContentCopy />}
                            />
                        )
                    })}
                </Box>}
        </>
    )
}
