import { Navigate } from "react-router-dom";
import { APP_DASHBOARD_V2_ROUTE } from "./DashboardV2.contants";
import { DashboardV2 } from "./DashboardV2";

export const dashboardV2Routes = [
    // {
    //     path: "/",
    //     index: true,
    //     exact: true,
    //     element: <Navigate to={APP_DASHBOARD_V2_ROUTE} replace />,
    // },
    {
        path: APP_DASHBOARD_V2_ROUTE,
        exact: true,
        element: <DashboardV2 />,
    },
] 