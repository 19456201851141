import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { Autocomplete, Stack, Typography } from "@mui/material";
import { CustomTextField } from "./CustomTextField";
import { AddOutlined } from "@mui/icons-material";
import { CustomFormLabel } from "./CustomFormLabel";

const CustomClearIcon = styled(ClearIcon)(({ theme }) => ({
  fontSize: "1.2rem",
}));

const ClearButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const CustomSelect = styled((props: SelectProps) => (
  <Select {...props} />
))(({ theme }) => ({
  '& .MuiOutlinedInput-root.Mui-error': {
    borderColor: "#fff",
  },
  '& .MuiInputLabel-root': {
    color: '#fff',
    top: "-5px",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: '#fff',
    top: 0
  },
  ".MuiInputLabel-outlined.Mui-focused": {
    color: '#fff',
    top: 0
  },
  '& label': {
    top: "-5px",
    borderColor: '#fff !important',
    padding: "9px 13px",
  },
  '& .MuiInputLabel-root.Mui-disabled': {
    color: "rgba(255, 255, 255, 0.5)",
  },
  "& .MuiSelect-select": {
    color: "#fff",
    padding: "9px 13px",
    fontSize: "1rem",
    '& fieldset ': {
      borderColor: '#fff !important',
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "5px",
    borderColor: `${theme.palette.mode === "dark" ? "#fff !important" : "#dee3e9"
      }`,
  },
  "& .MuiSelect-select::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
  "& .Mui-error": {
    borderColor: '#fff'
  }
}));









// ##############################################################################################
// ################################## ControlledCustomSelect ####################################
// ##############################################################################################

export type ICustomSelectProps = {
  options: { label: number | string | null; value: string | number }[];
  clearable?: boolean;
  onClear?: () => void;
  error?: string;
} & Omit<SelectProps, "error">

export const ControlledCustomSelect: React.FC<ICustomSelectProps> = (props) => {
  const renderPlaceholder = () => props.placeholder;
  const handleClear = () => {
    if (props.onClear) {
      props.onClear();
    }
  };

  const getOptionValue = () => {
    if (props.multiple && Array.isArray(props.value)) {
      return props.value;
    } else if (props.multiple || props.value === "[]") {
      return [];
    } else if (props.multiple) {
      return [props.value];
    }
    return props.value || "";
  };

  return (
    <FormControl fullWidth sx={{
      ".MuiInputLabel-formControl": {
        color: "#fff",
        top: (props.value || "").toString().length > 0 ? 0 : "-5px",
      },
      ".MuiInputLabel-formControl.Mui-focused": {
        color: "#fff",
        top: 0,
      }
    }}>
      <InputLabel id={props.name}>{props.label}</InputLabel>
      <CustomSelect
        {...props}
        id={props.name}
        labelId={props.name}
        // label={props.label}
        multiple={props.multiple}
        value={getOptionValue()}
        fullWidth
        size="small"
        renderValue={(selected) => {
          if (selected) {
            return getLabelFromValue(selected, props.options);
          }
          return renderPlaceholder();
        }}
        endAdornment={
          props.clearable && props.value ? (
            <InputAdornment position="end" sx={{ marginRight: "7px" }}>
              <ClearButton onClick={handleClear}>
                <CustomClearIcon />
              </ClearButton>
            </InputAdornment>
          ) : null
        }
        error={props.error ? true : false}
      >
        {props.options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {props.multiple && Array.isArray(props.value) && (
                <Checkbox checked={props.value.indexOf(option.value) > -1} />
              )}
              {option.label}
            </MenuItem>
          );
        })}
      </CustomSelect>
      {props.error &&

        <Typography
          variant="body2"
          sx={{
            textAlign: "left",
            fontSize: "0.75rem",
            color: "#fff",
            marginLeft: 2,
            marginTop: 0.75,
          }}
        >
          {props.error}
        </Typography>
      }
    </FormControl>
  );
};

const getLabelFromValue = (
  value: any,
  options: ICustomSelectProps["options"]
) => {
  if (Array.isArray(value)) {
    const data = options.filter((option) => {
      if (value.includes(option.value)) {
        return true;
      }
      return false;
    });
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {data
          .map((x) => x.label)
          .map((value) => (
            <Chip key={value} label={value} size="small" />
          ))}
      </Box>
    );
    // return value;
  }
  const option = options.find((option) => option.value === value);
  return option ? option.label : "";
};



// ##############################################################################################
// ##################################### ExtendableSelect #######################################
// ##############################################################################################

export interface IExtendableSelectProps {
  label: string;
  value: string | number | null;
  options: { label: string; value: string | number }[];
  onChange: (newValue: string | number) => void;
  error?: string;
  placeholder?: string;
}

export const ExtendableSelectField: React.FC<IExtendableSelectProps> = (props) => {
  const { value, label, error, placeholder, } = props;
  const [searchText, onSearchText] = React.useState("");
  const [options, setOptions] = React.useState(props.options);
  const [selectedValue, setSelectedValue] = React.useState<any>(null);

  const handleAdd = (e: React.MouseEvent<HTMLDivElement>) => {
    if (options.findIndex((o) => o.value === searchText) === -1) {
      setOptions((option) =>
        option.concat({ label: searchText, value: searchText })
      );
      if (props.onChange) {
        props.onChange(searchText);
      }
    }
  };
  React.useEffect(() => {
    if (value) {
      const data = options.find((option) => option.value === value);
      if (!data) {
        setOptions([
          ...props.options,
          { label: value.toString(), value: value },
        ]);
        setSelectedValue({ label: value.toString(), value: value })
      }
      else {
        setSelectedValue(data)
      }
    }
    else {
      setOptions([
        ...props.options,
      ]);
      setSelectedValue(null)
    }
  }, [value, props.options]);




  console.log("optionsoptionsoptionsoptionsoptionsoptionsoptions => ", options)
  return (
    <>
      <Autocomplete
        options={options}
        value={selectedValue}
        noOptionsText={
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            onClick={handleAdd}
          >
            <AddOutlined />
            <Typography variant="h5"> {`Create new "${searchText}"`}</Typography>
          </Stack>
        }
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
          },

        }}
        getOptionLabel={(option) => option.label}
        onInputChange={(e, newValue) => {
          onSearchText(newValue);
        }}
        onChange={(event: any, newValue: any | null) => {
          if (props.onChange) {
            props.onChange(newValue ? newValue.value : null);
          }
        }}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            sx={{
              ".MuiInputLabel-root": {
                top: selectedValue?.toString().length > 0 ? 0 : "-5px",
              },
              ".MuiInputLabel-root.Mui-focused": {
                top: 0,
              }
            }}
            label={label}
            variant="outlined"
            error={error ? true : false}
            helperText={error}
            placeholder={placeholder}
          />
        )}
      />
    </>
  );
};











interface ICustomSelectorProps extends Omit<SelectProps, "error" | "value"> {
  label?: string;
  options: string[] | number[];
  error?: string;
  readOnlyMode?: boolean;
  value?: string | number |  null
}

export const CustomSelectorWithLabel: React.FC<ICustomSelectorProps> = (
  props,
) => {
  const { error, label, readOnlyMode, ...restProps } = props;
  return (
    <>
      {label && <CustomFormLabel>{label}</CustomFormLabel>}
      {props.readOnlyMode ? (
        <Typography variant="body1" padding={1} fontSize={"0.8rem"}>
          {props.value || "--"}
        </Typography>
      ) : (
        <CustomSelect
          {...restProps}
          className={props.name}
          value={props.value}
          placeholder={props.placeholder}
        >
          {props.options.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {/* {props.multiple && Array.isArray(props.value) && (
                <Checkbox checked={props.options.indexOf(option) > -1} />
              )} */}
                {option}
              </MenuItem>
            );
          })}
        </CustomSelect>
      )}
      {error && (
        <Typography
          variant="body2"
          sx={{
            textAlign: "left",
            fontSize: "0.75rem",
            color: "#ff0000",
            marginLeft: 2,
            marginTop: 0.75,
          }}
        >
          {error}
        </Typography>
      )}
    </>
  );
};
