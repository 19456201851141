import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { defaultReceiptComposerState } from "./receipt-composer.state";
import { getReceiptComposerDetailsFormLocalStore, setReceiptComposerDetailsInLocalStore } from "./receipt-composer.localstore";
import { IReceiptComposer } from "./receipt-composer.types";
import { LoadState } from "../../../constants/enum";
import { fetchOutletReceiptsListAsync, fetchSingleOutletReceiptInfoAsync } from "./receipt-composer.actions";



const receiptComposerSlice = createSlice({
    initialState: defaultReceiptComposerState,
    name: "receipt-composer",
    reducers: {
        clearOutletReceiptsListSync: (state) => {
            return defaultReceiptComposerState
        },
        clearSingleOutletReceiptsSync: (state) => {
            return defaultReceiptComposerState
        },
    },
    extraReducers: (builder) => {
        // ############################# Multiple Product Categories ######################################
        builder.addCase(fetchOutletReceiptsListAsync.pending, (state, action) => {
            state.receipts_list.loading = LoadState.pending
        })
        builder.addCase(fetchOutletReceiptsListAsync.fulfilled, (state, action) => {
            state.receipts_list.loading = LoadState.succeeded
            state.receipts_list.data = action.payload.data
            state.receipts_list.count = action.payload.count
            state.receipts_list.error = null
        })
        builder.addCase(fetchOutletReceiptsListAsync.rejected, (state, action) => {
            state.receipts_list.error = action.error.message as string
        })


        // #################################### Single Product Category ##############################################
        builder.addCase(fetchSingleOutletReceiptInfoAsync.pending, (state) => {
            state.single_receipt.loading = LoadState.pending
        })
        builder.addCase(fetchSingleOutletReceiptInfoAsync.fulfilled, (state, action) => {
            state.single_receipt.loading = LoadState.succeeded
            state.single_receipt.data = action.payload
            state.single_receipt.error = null
        })
        builder.addCase(fetchSingleOutletReceiptInfoAsync.rejected, (state, action) => {
            state.single_receipt.error = action.error.message as string
        })
    },
});

export const receiptComposerReducer = receiptComposerSlice.reducer;
export const {
    clearOutletReceiptsListSync,
    clearSingleOutletReceiptsSync
  
} = receiptComposerSlice.actions;
