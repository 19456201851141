import React, { useState } from 'react'
import { DialogContent, Box, Typography, IconButton, DialogActions, Grid } from '@mui/material'
import { IUberEatsOrder } from '../../../../../redux/uber-eats'
import { IOrderScreenType } from '../ViewOrderDialog.types'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomButton } from '../../../../../Components/CustomButton';
import moment from 'moment';




interface IDelayOrderScreenProps {
    order_info: IUberEatsOrder,
    onBackIconClick: () => void
    onListClick: (type: IOrderScreenType) => void
    onClose: () => void
}

export const DelayOrderScreen: React.FC<IDelayOrderScreenProps> = ({
    order_info,
    onListClick,
    onBackIconClick,
    onClose
}) => {


    const [delayedTime, setDelayedTime] = useState<number>(0)


    const handleOnConfirmClick = () => {
        alert(`updated time is ${moment().add(delayedTime, "minutes").format('hh:mma')}`)
        onClose()
    }

    return (
        <>
            <Box sx={{
                minHeight: 70,
                width: '100%',
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 2,
                background: "#292929",
                paddingInline: 2,
                paddingBlock: 2,
                borderRadius: 1,
                cursor: "pointer"
            }}
            >
                <IconButton sx={{ p: .75 }} onClick={onBackIconClick}>
                    <ArrowBackIcon sx={{ height: 26, width: 26 }} />
                </IconButton>
                <Typography variant='h2'>
                    Delay
                </Typography>

            </Box >

            <DialogContent dividers sx={{  minHeight: 390, px: 8, py: 4 }}>
                <Typography variant='h3'>
                    How much additional time do you need?
                </Typography>
                <Typography variant='h5'>
                    We'll notify the customer about the delay.
                </Typography>
                <Typography variant='h5' my={2}>
                    {`Due at ${moment().format('hh:mma')} ${delayedTime > 0 ? (`${delayedTime} "mins"`) : ""}`}
                </Typography>


                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <CustomButton
                            variant={delayedTime === 5 ? "contained" : "outlined"}
                            color="primary"
                            size="large"
                            fullWidth
                            sx={{
                                minWidth: 100,
                                paddingInline: "16px !important",
                                textTransform: "uppercase"
                            }}
                            onClick={() => setDelayedTime(5)}
                        >
                            +5 mins
                        </CustomButton>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomButton
                            variant={delayedTime === 10 ? "contained" : "outlined"}
                            color="primary"
                            size="large"
                            fullWidth
                            sx={{
                                minWidth: 100,
                                paddingInline: "16px !important",
                                textTransform: "uppercase"
                            }}
                            onClick={() => setDelayedTime(10)}
                        >
                            +10 mins
                        </CustomButton>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomButton
                            variant={delayedTime === 20 ? "contained" : "outlined"}
                            color="primary"
                            size="large"
                            fullWidth
                            sx={{
                                minWidth: 100,
                                paddingInline: "16px !important",
                                textTransform: "uppercase"
                            }}
                            onClick={() => setDelayedTime(20)}
                        >
                            +20 mins
                        </CustomButton>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomButton
                            variant={delayedTime === 30 ? "contained" : "outlined"}
                            color="primary"
                            size="large"
                            fullWidth
                            sx={{
                                minWidth: 100,
                                paddingInline: "16px !important",
                                textTransform: "uppercase"
                            }}
                            onClick={() => setDelayedTime(30)}
                        >
                            +30 mins
                        </CustomButton>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{ minHeight: 60, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <CustomButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    sx={{
                        minWidth: 100,
                        paddingInline: "16px !important",
                        textTransform: "uppercase"
                    }}
                    onClick={onClose}
                >
                    Cancel
                </CustomButton>
                <CustomButton
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                        minWidth: 100,
                        paddingInline: "16px !important",
                        textTransform: "uppercase"
                    }}
                    disabled={delayedTime <= 0}
                    onClick={handleOnConfirmClick}
                >
                    Confirm
                </CustomButton>
            </DialogActions>
        </>
    )
}
