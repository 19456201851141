import React, { useEffect } from "react";
import { useAppDispatch } from "../../redux";
import { clearSingleOrderStateSync, defaultOrder, upsertSingleOrderWithCallbackAsync } from "../../redux/orders";
import { POS_ORDERS_ROUTE, POS_APP_MENU_ROUTE_NEW_ORDER } from "./MenuAndOrders.constants";
import { OrderMenuLayout } from "./OrderMenu/OrderMenuLayout";
import { POSOrdersTable } from "./PosOrders/POSOrdersTable";
import { ViewSinglePOSOrder } from "./PosOrders/ViewSinglePOSOrder";
import { useNavigate } from "react-router-dom";

export const CreateNewOrder: React.FC<{}> = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(clearSingleOrderStateSync())
        navigate(`${POS_APP_MENU_ROUTE_NEW_ORDER}?type=TAKE_AWAY`)
    }, [])

    return <></>
}

export const posOrderRoutes = [
    {
        path: POS_ORDERS_ROUTE,
        exact: true,
        element: <POSOrdersTable />
    },
    {
        path: `${POS_ORDERS_ROUTE}/view/:uuid`,
        exact: true,
        element: <OrderMenuLayout />
    },
    {
        path: `${POS_APP_MENU_ROUTE_NEW_ORDER}/create`,
        exact: true,
        element: <CreateNewOrder />
    },
    {
        path: `${POS_APP_MENU_ROUTE_NEW_ORDER}`,
        exact: true,
        element: <OrderMenuLayout />
    },
    {
        path: `${POS_APP_MENU_ROUTE_NEW_ORDER}/edit/:uuid`,
        exact: true,
        element: <OrderMenuLayout />
    },
]

