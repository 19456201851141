import { createAsyncThunk } from "@reduxjs/toolkit";
import { IQueryParams, getSearchQuery } from "../../../common";
import { axios_api } from "../../../api/axios-api";
import { IPaymentPaid } from "./payment-paid.types";
import { uploadSingleFileAsync } from "../../../helpers/uploadFilesAsync";
import { closeLoaderWithMessage, openLoaderWithMessage } from "../../common";

const GET_ACCOUNTING_PAYMENTS_PAID_ENDPOINT = "/accounting/get-accounting-payment-paid"
const UPSERT_ACCOUNTING_PAYMENTS_PAID_ENDPOINT = "/accounting/upsert-accounting-payment-paid"

export const fetchPaymentPaidListWithArgsAsync = createAsyncThunk<{ recordCount: number, data: IPaymentPaid[] }, IQueryParams>(
    'payment-paid/fetchPaymentPaidListWithArgsAsync', async (queryParams: IQueryParams, thunkAPI) => {
        thunkAPI.dispatch(openLoaderWithMessage("Fetching Payment Details...!"))
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_ACCOUNTING_PAYMENTS_PAID_ENDPOINT}${searchQuery}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        thunkAPI.dispatch(closeLoaderWithMessage())
        return { recordCount: count, data: data }
    },
)

interface IUpsertSinglePaymentPaid extends IPaymentPaid {
    file: File | null,
}

export const upsertSinglePaymentPaidAsync = createAsyncThunk<IPaymentPaid, IUpsertSinglePaymentPaid>(
    'payment-paid/upsertSinglePaymentPaidAsync', async (payload: IUpsertSinglePaymentPaid, thunkAPI) => {
        const { file, create_ts, insert_ts, ...restPayload } = payload
        thunkAPI.dispatch(openLoaderWithMessage("Saving Changes...!"))
        if (file) {
            const path = await uploadSingleFileAsync({
                file,
                moduleName: "ACCOUNTING",
                asPayload: {
                    order_number: payload.order_number, // need to be discuss
                    file_name: file.name,
                    file_ext: file.name.split('.').pop() || "",
                    
                }
            })
            restPayload.attachment = path
        }
        thunkAPI.dispatch(closeLoaderWithMessage())
        const response = await axios_api.post(UPSERT_ACCOUNTING_PAYMENTS_PAID_ENDPOINT, restPayload)
        thunkAPI.dispatch(fetchPaymentPaidListWithArgsAsync({
            page: 1,
            rowsPerPage: 10
        }))
        return response.data
    },
)