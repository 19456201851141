import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { defaultOrderDiscountCoupon, defaultOrderItem, defaultOrderState } from "./orders.state";
import { fetchOrdersListAsync, fetchOrdersListWithArgsAsync, fetchSingleOrderAsync, upsertSingleOrderWithCallbackAsync } from "./orders.actions";
import { LoadState } from "../../constants/enum";
import { IProduct, IProductModifier } from "../inventory";
import { isNotNull } from "../../helpers/isNotNull";
import { IOrder, IOrderDiscountCoupon } from "./orders.types";
import { calculateOrderItemCalculations } from "./orders.helpers";

const ordersSlice = createSlice({
    initialState: defaultOrderState,
    name: "orders",
    reducers: {
        insertItemIntoOrderSync: (state, action: PayloadAction<{ product: IProduct, quantity: number }>) => {
            const { product_uuid, product_name, product_price, product_tax,
                product_price_by, weight_per_unit, product_weight_unit
            } = action.payload.product;
            const quantity = action.payload.quantity;

            const isPresent = state.single_order?.data.menu_items.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                state.single_order.data.menu_items.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        if (product_price_by === "WEIGHT") {
                            const per_unit_price = isNotNull(product_price) / isNotNull(weight_per_unit)
                            const current_weight_price = (isNotNull(quantity)) * per_unit_price;
                            item.quantity = quantity;
                            item.product_price = current_weight_price
                        } else {
                            item.quantity = quantity;
                            item.product_price = isNotNull(item.product_price) + isNotNull(product_price);
                        }
                    }
                    return item;
                });
                const draftState = { ...state.single_order.data }
                const finalState = calculateOrderItemCalculations(draftState)
                state.single_order.data = finalState
            } else {
                if (product_price_by === "WEIGHT") {
                    const per_unit_price = isNotNull(product_price) / isNotNull(weight_per_unit)
                    const current_weight_price = (isNotNull(quantity)) * per_unit_price;
                    state.single_order.data.menu_items.push({
                        ...defaultOrderItem,
                        product_uuid: product_uuid as string,
                        product_name,
                        product_price: current_weight_price,
                        product_price_by,
                        product_weight_unit,
                        tax_percentage: isNotNull(product_tax),
                        quantity: quantity,
                    });
                } else {
                    state.single_order.data.menu_items.push({
                        ...defaultOrderItem,
                        product_uuid: product_uuid as string,
                        product_name,
                        product_price,
                        tax_percentage: isNotNull(product_tax),
                        quantity: quantity,
                    });
                }
                const draftState = { ...state.single_order.data }
                const finalState = calculateOrderItemCalculations(draftState)
                state.single_order.data = finalState
            }
        },
        removeItemFromOrderSync: (state, action: PayloadAction<{ product: IProduct, quantity: number }>) => {
            const { product_uuid, product_price, product_price_by, weight_per_unit } = action.payload.product;
            const quantity = action.payload.quantity;
            const currentStateItems = [...state.single_order.data.menu_items]
            const isPresent = currentStateItems.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                currentStateItems.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        if (item.product_uuid === product_uuid) {
                            if (product_price_by === "WEIGHT") {
                                const per_unit_price = isNotNull(product_price) / isNotNull(weight_per_unit)
                                const current_weight_price = (isNotNull(quantity)) * per_unit_price;
                                item.quantity = quantity;
                                item.product_price = current_weight_price
                            } else {
                                item.quantity = quantity;
                                item.product_price = isNotNull(quantity) * isNotNull(product_price);
                            }
                        }
                    }
                    return item;
                });
                const draftState = { ...state.single_order.data }
                const finalState = calculateOrderItemCalculations(draftState)
                state.single_order.data = finalState
                state.single_order.data.menu_items = currentStateItems.filter((item) => item.quantity > 0);
            }
        },
        changeItemQuantitySync: (state, action: PayloadAction<{ product: IProduct, quantity: number }>) => {
            const { product_uuid, product_name, product_price, product_tax,
                product_price_by, weight_per_unit, product_weight_unit } = action.payload.product;
            const quantity = action.payload.quantity;
            // const currentStateItems = [...state.single_order.data.menu_items]
            const isPresent = state.single_order.data.menu_items.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                state.single_order.data.menu_items.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        if (product_price_by === "WEIGHT") {
                            const per_unit_price = isNotNull(product_price) / isNotNull(weight_per_unit)

                            const current_weight_price = isNotNull(quantity) * per_unit_price;
                            item.quantity = quantity;
                            item.product_price = current_weight_price
                        } else {
                            item.product_price = isNotNull(quantity) * isNotNull(product_price);
                            item.quantity = quantity;
                            item.tax_percentage = product_tax
                        }
                    }
                    return item;
                });
            } else {
                if (product_price_by === "WEIGHT") {
                    const per_unit_price = isNotNull(product_price) / isNotNull(weight_per_unit)

                    const current_weight_price = isNotNull(quantity) * per_unit_price;

                    state.single_order.data.menu_items.push({
                        ...defaultOrderItem,
                        product_uuid: product_uuid as string,
                        product_name,
                        product_price: current_weight_price,
                        product_price_by: product_price_by,
                        product_weight_unit: product_weight_unit,
                        tax_percentage: isNotNull(product_tax),
                        quantity: quantity,
                    });
                } else {
                    state.single_order.data.menu_items.push({
                        ...defaultOrderItem,
                        product_uuid: product_uuid as string,
                        product_name,
                        product_price,
                        tax_percentage: isNotNull(product_tax),
                        quantity: quantity,
                    });
                }
            }
            const draftState = { ...state.single_order.data }
            const finalState = calculateOrderItemCalculations(draftState)
            state.single_order.data = finalState
            state.single_order.data.menu_items = [...finalState.menu_items].filter((item) => item.quantity > 0);
        },

        changeOrderItemNoteFieldSync: (state, action: PayloadAction<{ product: IProduct, note: string }>) => {
            const { product_uuid, product_name, product_price, product_tax } = action.payload.product;
            const isPresent = state.single_order.data.menu_items.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                state.single_order.data.menu_items.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        item.notes = action.payload.note
                    }
                    return item;
                });
            } else {
                // state.single_order.data.menu_items.push({
                //     product_uuid: product_uuid as string,
                //     product_name,
                //     product_price,
                //     tax_percentage: isNotNull(product_tax),
                //     quantity: 1,
                //     notes: ""
                // });
            }
        },

        addProductModifierIntoOrderSync: (state, action: PayloadAction<{ product: IProduct, modifier: IProductModifier }>) => {
            const { product_uuid, product_name, product_price, product_tax } = action.payload.product;
            const productModifier = action.payload.modifier;
            const isPresent = state.single_order.data.menu_items.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                state.single_order.data.menu_items.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        const isOptionPresent = item.options.find((option) => option.modifier_uuid === productModifier.modifier_uuid);
                        if (isOptionPresent) {
                            item.options.map((option) => {
                                if (option.modifier_uuid === productModifier.modifier_uuid) {
                                    option.quantity += 1
                                    option.modifier_price = isNotNull(option.modifier_price) + isNotNull(productModifier.modifier_price)
                                }
                                return option
                            })

                        } else {
                            item.options.push({
                                modifier_uuid: productModifier.modifier_uuid || "",
                                modifier_name: productModifier.modifier_name,
                                customization_uuid: productModifier.customization_uuid,
                                modifier_price: productModifier.modifier_price,
                                tax_percentage: product_tax,
                                quantity: 1
                            })
                        }
                    }
                    return item;
                });
            } else {
                state.single_order.data.menu_items.push({
                    ...defaultOrderItem,
                    product_uuid: product_uuid as string,
                    product_name,
                    product_price,
                    tax_percentage: isNotNull(product_tax),
                    quantity: 1,
                    options: [{
                        modifier_uuid: productModifier.modifier_uuid || "",
                        modifier_name: productModifier.modifier_name,
                        customization_uuid: productModifier.customization_uuid,
                        modifier_price: productModifier.modifier_price,
                        tax_percentage: product_tax,
                        quantity: 1
                    }],
                    notes: "",
                    is_served: false
                });
            }
        },

        changeProductModifierQuantitySync: (state, action: PayloadAction<{ product: IProduct, modifier: IProductModifier, quantity: number }>) => {
            const { product_uuid, product_name, product_price, product_tax } = action.payload.product;
            const productModifier = action.payload.modifier;
            const modifierQuantity = action.payload.quantity;

            const isPresent = state.single_order.data.menu_items.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                state.single_order.data.menu_items.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        const isOptionPresent = item.options.find((option) => option.modifier_uuid === productModifier.modifier_uuid);
                        if (isOptionPresent) {
                            item.options.map((option) => {
                                if (option.modifier_uuid === productModifier.modifier_uuid) {
                                    option.quantity = modifierQuantity
                                    option.modifier_price = isNotNull(productModifier.modifier_price) * isNotNull(modifierQuantity)
                                }
                                return option
                            })
                        } else {
                            item.options.push({
                                modifier_uuid: productModifier.modifier_uuid || "",
                                modifier_name: productModifier.modifier_name,
                                customization_uuid: productModifier.customization_uuid,
                                modifier_price: isNotNull(productModifier.modifier_price) * isNotNull(modifierQuantity),
                                tax_percentage: product_tax,
                                quantity: modifierQuantity
                            })
                        }
                    }
                    return item;
                });
            } else {
                state.single_order.data.menu_items.push({
                    ...defaultOrderItem,
                    product_uuid: product_uuid as string,
                    product_name,
                    product_price,
                    tax_percentage: isNotNull(product_tax),
                    quantity: 1,
                    options: [{
                        modifier_uuid: productModifier.modifier_uuid || "",
                        modifier_name: productModifier.modifier_name,
                        customization_uuid: productModifier.customization_uuid,
                        modifier_price: isNotNull(productModifier.modifier_price) * isNotNull(modifierQuantity),
                        tax_percentage: product_tax,
                        quantity: modifierQuantity
                    }],
                    notes: "",
                    is_served: false
                });
            }
        },

        removeProductModifierFromOrderSync: (state, action: PayloadAction<{ product: IProduct, modifier: IProductModifier }>) => {
            const { product_uuid } = action.payload.product;
            const productModifier = action.payload.modifier;

            const currentStateItems = [...state.single_order.data.menu_items]
            const isPresent = currentStateItems.find((item) => item.product_uuid === product_uuid);
            if (isPresent) {
                currentStateItems.map((item) => {
                    if (item.product_uuid === product_uuid) {
                        const isOptionPresent = item.options.find((option) => option.modifier_uuid === productModifier.modifier_uuid);
                        if (isOptionPresent) {
                            item.options.map((option) => {
                                if (option.modifier_uuid === productModifier.modifier_uuid) {
                                    option.quantity -= 1
                                    option.modifier_price = isNotNull(option.modifier_price) - isNotNull(productModifier.modifier_price)
                                }
                                return option
                            })
                            item.options = item.options.filter((option) => option.quantity > 0)
                        }
                    }
                    return item;
                });
                state.single_order.data.menu_items = currentStateItems.filter((item) => item.quantity > 0);
            }
        },

        addCouponCodeItemInOrderSync: (state) => {
            state.single_order.data.discount_coupons.push(defaultOrderDiscountCoupon)
        },
        removeCouponCodeFromOrderSync: (state, action: PayloadAction<number>) => {
            const couponIndex = action.payload
            let currentOrder = { ...state.single_order.data }
            currentOrder.total_amount += Number(currentOrder.extra_charges[couponIndex].amount)
            currentOrder.discount_coupons.splice(couponIndex, 1)
            state.single_order.data = currentOrder
        },
        applyCouponCodeToOrderSync: (state, action: PayloadAction<{ coupon: IOrderDiscountCoupon, index: number }>) => {
            const couponInfo = action.payload.coupon
            const couponIndex = action.payload.index
            let currentOrder = { ...state.single_order.data }
            currentOrder.discount_coupons[couponIndex] = couponInfo
            currentOrder.total_amount = currentOrder.total_amount - Number(couponInfo.amount)

            state.single_order.data = {
                ...currentOrder
            }
        },



        addExtraChargesFieldInOrderSync: (state) => {
            state.single_order.data.extra_charges.push({
                title: "",
                amount: ""
            })
        },
        removeExtraChargesFieldFromOrderSync: (state, action: PayloadAction<number>) => {

            const currentIndex = action.payload
            let currentOrder = { ...state.single_order.data }
            currentOrder.total_amount -= isNotNull(currentOrder.extra_charges[currentIndex].amount)
            currentOrder.discount_coupons.splice(currentIndex, 1)
            state.single_order.data = currentOrder
        },

        applyExtraCharesToOrderSync: (state, action: PayloadAction<{ charges: { title: string, amount: string }, index: number }>) => {
            const extraChargesInfo = action.payload.charges
            const currentIndex = action.payload.index
            let currentOrder = { ...state.single_order.data }
            console.log("applyExtraCharesToOrderSync => ", state.single_order.data, currentOrder)

            currentOrder.extra_charges[currentIndex] = extraChargesInfo
            currentOrder.total_amount = currentOrder.total_amount + Number(extraChargesInfo.amount)

            state.single_order.data = {
                ...currentOrder
            }
        },

        createDineInOrderFromTableSync: (state, action: PayloadAction<IOrder>) => {
            state.single_order.data = action.payload
        },
        clearOrdersStateSync: (state) => {
            return defaultOrderState
        },
        clearSingleOrderStateSync: (state) => {
            state.single_order.data = defaultOrderState["single_order"]["data"]
            state.single_order.loading = defaultOrderState["single_order"]["loading"]
        },
    },
    extraReducers: (builder) => {
        //############################# fetchOrdersListAsync ######################################
        builder.addCase(fetchOrdersListAsync.pending, (state, action) => {
            state.orders_list.loading = LoadState.pending
        })
        builder.addCase(fetchOrdersListAsync.fulfilled, (state, action) => {
            state.orders_list.loading = LoadState.succeeded
            state.orders_list.data = action.payload.data
            state.orders_list.totalRecords = action.payload.recordCount
            state.orders_list.error = null
        })
        builder.addCase(fetchOrdersListAsync.rejected, (state, action) => {
            state.orders_list.error = action.error.message as string
        })

        //############################# fetchOrdersListWithArgsAsync ######################################
        builder.addCase(fetchOrdersListWithArgsAsync.pending, (state, action) => {
            state.orders_list.loading = LoadState.pending
        })
        builder.addCase(fetchOrdersListWithArgsAsync.fulfilled, (state, action) => {
            state.orders_list.loading = LoadState.succeeded
            state.orders_list.data = [...state.orders_list.data].concat(action.payload.data)
            state.orders_list.totalRecords = action.payload.recordCount
            state.orders_list.error = null
        })
        builder.addCase(fetchOrdersListWithArgsAsync.rejected, (state, action) => {
            state.orders_list.error = action.error.message as string
        })


        //#################################### fetchSingleOrderAsync ##############################################
        builder.addCase(fetchSingleOrderAsync.pending, (state, action) => {
            state.single_order.loading = LoadState.pending
        })
        builder.addCase(fetchSingleOrderAsync.fulfilled, (state, action) => {
            state.single_order.loading = LoadState.succeeded
            state.single_order.data = action.payload
            state.single_order.error = null
        })
        builder.addCase(fetchSingleOrderAsync.rejected, (state, action) => {
            state.single_order.error = action.error.message as string
        })



        //#################################### upsertSingleOrderWithCallbackAsync ##############################################
        //  builder.addCase(upsertSingleOrderWithCallbackAsync.pending, (state, action) => {
        //     state.single_order.loading = LoadState.pending
        // })
        builder.addCase(upsertSingleOrderWithCallbackAsync.fulfilled, (state, action) => {
            state.single_order.loading = LoadState.succeeded
            state.single_order.data = defaultOrderState["single_order"]["data"]
            state.single_order.error = null
        })
        // builder.addCase(upsertSingleOrderWithCallbackAsync.rejected, (state, action) => {
        //     state.single_order.error = action.error.message as string
        // })
    },
});

export const ordersReducer = ordersSlice.reducer;
export const {
    insertItemIntoOrderSync,
    removeItemFromOrderSync,
    changeItemQuantitySync,

    changeOrderItemNoteFieldSync,

    addProductModifierIntoOrderSync,
    changeProductModifierQuantitySync,
    removeProductModifierFromOrderSync,

    clearOrdersStateSync,
    clearSingleOrderStateSync,
    createDineInOrderFromTableSync,

    addCouponCodeItemInOrderSync,
    applyCouponCodeToOrderSync,
    removeCouponCodeFromOrderSync,

    addExtraChargesFieldInOrderSync,
    removeExtraChargesFieldFromOrderSync,
    applyExtraCharesToOrderSync
} = ordersSlice.actions;
