import { Box, Paper, Typography } from "@mui/material";
import { IProductCardProps } from "./interface/interface";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { HiMinusSm } from "react-icons/hi";
import { IStoreState } from "../../redux/store.types";
import { useAppSelector } from "../../redux";

export const ProductCard: React.FC<IProductCardProps> = (props) => {
  const { title, price, bgIsPurple, onAddItem, onRemoveItem } = props;
  // const { list: ordersInfo } = useAppSelector((state) => state.order)
  // const quantity = ordersInfo.find((item) => item.name === title)?.quantity;
  // return (
  //   <Box
  //     display={"flex"}
  //     alignItems={"flex-start"}
  //     justifyContent={"flex-start"}
  //   >
  //     <Paper
  //       square={false}
  //       sx={{
  //         display: "flex",
  //         flexDirection: "row",
  //         justifyContent: "flex-start",
  //         alignContent: "space-between",
  //         bgcolor: bgIsPurple ? "#FBC1D9" : "#2C2D2C" /* FBC1D9, 2C2D2C */,
  //         color: bgIsPurple ? "#000000" : "#FFFFFF",
  //         borderRadius: 1,
  //         width: "100%",
  //       }}
  //     >
  //       <Box
  //         width={"4%"}
  //         bgcolor={"#FBC1D9"}
  //         borderRadius={"8px 0 0 8px"}
  //       ></Box>
  //       <Box
  //         display={"flex"}
  //         flexDirection={"column"}
  //         gap={1.8}
  //         width={"96%"}
  //         pl={1.5}
  //       >
  //         <Box pt={1}>
  //           <Typography
  //             color={bgIsPurple ? "#6C6C6C" : "#D0D0D0"}
  //             variant="h6"
  //             fontWeight={"bold"}
  //             display={"flex"}
  //             alignItems={"center"}
  //             gap={0.5}
  //             justifyContent={"flex-start"}
  //           >
  //             order
  //             {/* <TrendingFlatOutlinedIcon fontSize="small" /> */}
  //             <FaLongArrowAltRight fontSize={"1rem"} />
  //             kitchen
  //           </Typography>
  //         </Box>
  //         <Box>
  //           <Typography variant="h4" fontWeight={"bold"} letterSpacing={-0.5}>
  //             {title}
  //           </Typography>
  //           <Typography
  //             variant="h6"
  //             color={bgIsPurple ? "#6C6C6C" : "#D0D0D0"}
  //             fontWeight={"600"}
  //           >
  //             {" "}
  //             {/* 6C6C6C, D0D0D0 */}
  //             {price}
  //           </Typography>
  //         </Box>
  //         <Box
  //           display={"flex"}
  //           justifyContent={"flex-end"}
  //           alignItems={"center"}
  //           gap={2}
  //           pr={5}
  //           pb={2}
  //         >
  //           <HiMinusSm
  //             style={{
  //               color: bgIsPurple ? "#6C6C6C" : "#D0D0D0",
  //               border: "1px solid",
  //               borderColor: bgIsPurple ? "#6C6C6C" : "#D0D0D0",
  //               borderRadius: "0.3rem",
  //               padding: "0.1rem",
  //               fontSize: "1.5rem",
  //             }}
  //             onMouseEnter={(e) => {
  //               e.currentTarget.style.backgroundColor = bgIsPurple
  //                 ? "#6C6C6C"
  //                 : "#D0D0D0";
  //               e.currentTarget.style.color = bgIsPurple
  //                 ? "#FBC1D9"
  //                 : "#2C2D2C";
  //               e.currentTarget.style.cursor = "pointer";
  //             }}
  //             onMouseLeave={(e) => {
  //               e.currentTarget.style.backgroundColor = bgIsPurple
  //                 ? "#FBC1D9"
  //                 : "#2C2D2C";
  //               e.currentTarget.style.color = bgIsPurple
  //                 ? "#6C6C6C"
  //                 : "#D0D0D0";
  //               e.currentTarget.style.cursor = "pointer";
  //             }}
  //             onClick={onRemoveItem}
  //           />

  //           <Typography
  //             variant="h6"
  //             color={bgIsPurple ? "#6C6C6C" : "#D0D0D0"}
  //             fontWeight={"500"}
  //           >
  //             {quantity || 0}
  //           </Typography>

  //           <FaPlus
  //             style={{
  //               color: bgIsPurple ? "#6C6C6C" : "#D0D0D0",
  //               border: "1px solid",
  //               borderColor: bgIsPurple ? "#6C6C6C" : "#D0D0D0",
  //               borderRadius: "0.3rem",
  //               padding: "0.3rem",
  //               fontSize: "1.5rem",
  //             }}
  //             onMouseEnter={(e) => {
  //               e.currentTarget.style.backgroundColor = bgIsPurple
  //                 ? "#6C6C6C"
  //                 : "#D0D0D0";
  //               e.currentTarget.style.color = bgIsPurple
  //                 ? "#FBC1D9"
  //                 : "#2C2D2C";
  //               e.currentTarget.style.cursor = "pointer";
  //             }}
  //             onMouseLeave={(e) => {
  //               e.currentTarget.style.backgroundColor = bgIsPurple
  //                 ? "#FBC1D9"
  //                 : "#2C2D2C";
  //               e.currentTarget.style.color = bgIsPurple
  //                 ? "#6C6C6C"
  //                 : "#D0D0D0";
  //               e.currentTarget.style.cursor = "pointer";
  //             }}
  //             onClick={onAddItem}
  //           />
  //         </Box>
  //       </Box>
  //     </Paper>
  //   </Box>
  // );
  return <></>
};

// background-color: #2C2D2C;
// color : #FFFFFF;, D0D0D0

// background-color: #FBC1D9;
// color : #000000; 6C6C6C
