import type { BoxProps } from '@mui/material/Box';
import type { CardProps } from '@mui/material/Card';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import ListItemText from '@mui/material/ListItemText';

import { Iconify } from '../components/iconify';
import { Scrollbar } from '../components/scrollbar';
import { Typography } from '@mui/material';
import { IStoreState, useAppDispatch, useAppSelector } from '../../../redux';
import React from 'react';
import { fetchAllUsersProfileAsync } from '../../../redux/settings/user-profile/user-profile.actions';
import { clearAllUsersStateSync, IUserProfile } from '../../../redux/settings/user-profile';
import { ALL_USERS_LIST_ROUTE } from '../../Settings/UserProfile/UserProfile.constant';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

type Props = CardProps & {
  title?: string;
  subheader?: string;
  list: {
    id: string;
    name: string;
    email: string;
    avatarUrl: string;
  }[];
};

export function BankingContacts({ title, subheader, list, ...other }: Props) {
  const {
    data: allUsersData,
    loading,
    totalRecords
  } = useAppSelector((storeState: IStoreState) => storeState.settings.userProfile.users_list);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(
      fetchAllUsersProfileAsync({
        page: 1,
        rowsPerPage: 5,
      }),
    );

    return () => {
      dispatch(clearAllUsersStateSync());
    };
  }, []);



  const handleOnViewAllUsersClick = () => {
    navigate(ALL_USERS_LIST_ROUTE)
  }
  return (
    <Card {...other}>
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: "h3"
        }}
        subheader={`You have ${totalRecords} Users`}
        action={
          <Button
            size="small"
            color="inherit"
            onClick={handleOnViewAllUsersClick}
            endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
          >
            View all
          </Button>
        }
      />

      <Scrollbar sx={{ minHeight: 200 }}>
        <Box
          sx={{
            p: 3,
            gap: 3,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 200,
          }}
        >
          {allUsersData.map((item) => (
            <Item key={item.user_uuid} item={item} />
          ))}
        </Box>
      </Scrollbar>
    </Card>
  );
}

// ----------------------------------------------------------------------

type ItemProps = BoxProps & {
  item: IUserProfile;
};

function Item({ item, sx, ...other }: ItemProps) {
  return (
    <Box key={item.user_uuid} sx={{ gap: 2, display: 'flex', alignItems: 'center', justifyContent: "space-between", ...sx }} {...other}>

      <Box sx={{ display: "flex", gap: 2 }}>
        <Avatar src={item.profile_picture || ""} />
        <Box>
          <Typography variant='h5'>{item.first_name}</Typography>
          <Typography variant='h5' sx={{ opacity: 0.7 }}>{item.email}</Typography>
        </Box>

      </Box>

      <Tooltip title="Quick transfer">
        <IconButton>
          <Iconify icon="solar:transfer-horizontal-bold-duotone" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
