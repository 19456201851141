import React from "react";
import {
  Box,
  Typography,
  Button,
} from "@mui/material";
//@ts-ignore
// import FeatherIcon from "feather-icons";
import profileCover from "../../assests/dummy_image.jpg";

interface IProfileIntroCardProps {
  title?: string;
  subTitle?: string
  fileUrl: string | null;
  onChange: (path: File) => void;
  onFileReset: () => void

}
// Change Profile
// Change your profile picture from here
export const ProfileIntroCard: React.FC<IProfileIntroCardProps> = ({
  title,
  subTitle,
  fileUrl,
  onChange,
  onFileReset
}) => {
  const [localFile, setLocalFile] = React.useState<string | null>(null);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileUrl = URL.createObjectURL(file);
      setLocalFile(fileUrl)
      onChange(file);
    }
  };

  const handleFileReset = () => {
    setLocalFile(null)
    onFileReset()
  }
  return (
    <>
      <Box>
        {title && <Typography variant="h2" fontWeight={"700"}>{title}</Typography>}
        {subTitle && <Typography variant="body2"> {subTitle} </Typography>}
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        marginTop={3}
      >
        <Box sx={{ height: 200, width: 200, borderRadius: "100px", background: "#fff" }}>
          {(localFile && localFile.length > 0) ?
            <img
              src={localFile}
              alt="local file"
              style={{ height: "100%", width: "100%", borderRadius: "50%" }}
            />
            : (fileUrl && fileUrl.length > 0) ?
              <img
                src={`${process.env.REACT_APP_S3_PUBLIC_PREFIX_URL}${fileUrl}`}
                alt="logo"
                style={{ height: "100%", width: "100%", borderRadius: "50%" }}
              />
              :
              <img
                src={profileCover}
                alt="logo not found"
                style={{ height: "100%", width: "100%", borderRadius: "50%" }}
              />
          }
        </Box>

        <Box display={"flex"} marginTop={3}>
          <Box sx={{ mr: 1 }}>
            <input
              style={{ display: "none" }}
              id="file-upload"
              type="file"
              onChange={onFileChange}
            />
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                component="span"
                sx={{ background: "warning.main" }}
              >
                Upload
              </Button>
            </label>
          </Box>
          <Box>
            <Button variant="outlined" onClick={handleFileReset}>
              Reset
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
