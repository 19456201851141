import { defaultChartOfAccountState, defaultPaymentPaidState, defaultPaymentReceivedState, defaultPosBankInfoState } from "./accounting";
import { defaultCustomizerState } from "./customizer";
import { defaultInventoryState, defaultProductCustomizationState, defaultProductState } from "./inventory";
import { defaultCategoryState } from "./inventory/categories/categories.state";
import { defaultReceiptComposerState } from "./settings/receipt-composer";
import { defaultShiftPunchInOutState } from "./shifts";
import { IStoreState } from "./store.types";
import { defaultUserProfileState } from "./settings/user-profile";
import { defaultTaxInfoState } from "./settings/tax-info";
import { defaultCompanyState } from "./settings/company";
import { defaultCompanyOutletState } from "./settings/outlets";
import { defaultOutletRegisterState } from "./settings/outlet-registers";
import { defaultSecurityState } from "./settings/security";
import { defaultCouponState } from "./marketing";
import { defaultCampaignState } from "./marketing/campaigns";
import { defaultOrderState } from "./orders";
import { defaultTablesLayoutState } from "./tables";
import { defaultReservationState } from "./reservations";
import { defaultCommonState } from "./common/common.state";
import { defaultUberEatsOrderState } from "./uber-eats";

export const initialStoreState: IStoreState = {
  // Add your initial state here
  common: defaultCommonState,
  inventory: {
    categories: defaultCategoryState,
    products: defaultProductState,
    inventory: defaultInventoryState,
    customization: defaultProductCustomizationState
  },
  tables: defaultTablesLayoutState,
  reservations: defaultReservationState,
  orders: defaultOrderState,
  accounting: {
    paymentReceived: defaultPaymentReceivedState,
    paymentPaid: defaultPaymentPaidState,
    bankInfo: defaultPosBankInfoState,
    chartOfAccount: defaultChartOfAccountState
  },
  marketing: {
    campaigns: defaultCampaignState,
    coupons: defaultCouponState
  },
  shifts: defaultShiftPunchInOutState,
  settings: {
    receiptComposer: defaultReceiptComposerState,
    companies: defaultCompanyState,
    outlets: defaultCompanyOutletState,
    registers: defaultOutletRegisterState,
    tax: defaultTaxInfoState,
    userProfile: defaultUserProfileState,
    security: defaultSecurityState
  },
  customizer: defaultCustomizerState,
  uberEats: {
    uberEatsOrders: defaultUberEatsOrderState
  },
};
