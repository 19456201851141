import React, { useEffect, useRef, useState } from 'react'
import { RightPanel } from '../../../Components/RightPanel';
import { Grid, Button, Box, Typography } from '@mui/material';
import { CustomFormLabel } from '../../../Components/formComponents';
import { useAppDispatch } from '../../../redux';
import { fetchSingleOrderEJSAsync, IFetchOrderEjs } from '../../../redux/orders';
import { useReactToPrint } from 'react-to-print';

interface IOrderPrintDialogProps {
    open: boolean,
    onClose: () => void,
    data: {
        order_uuid: string,
        outlet_uuid: string,
        order_type: IFetchOrderEjs["order_type"]
    }
}
export const OrderPrintDialog: React.FC<IOrderPrintDialogProps> = ({
    open,
    onClose,
    data
}) => {
    const dispatch = useAppDispatch()
    const { order_uuid, outlet_uuid, order_type } = data

    const contentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({ contentRef });
    const [currentOrderData, setCurrentOrderData] = useState("")

    useEffect(() => {
        fetchEjsData()
    }, [data])

    const fetchEjsData = () => {
        dispatch(fetchSingleOrderEJSAsync({
            order_uuid, outlet_uuid, order_type, onCallback(isSuccess, data) {
                if (isSuccess && data) {
                    setCurrentOrderData(data)
                }
            }
        }))
    }
    return (
        <RightPanel
            open={open}
            heading="Print Receipt"
            onClose={onClose}
            // isWrappedWithForm
            // onFormSubmit={printCurrentOrderDetails}
            actionButtons={() => {
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Button
                                variant="contained"
                                onClick={() => handlePrint()}
                                fullWidth
                            >
                                Print
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                );
            }}

        >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box ref={contentRef} sx={{ maxWidth: 288, color: "#000", background: "#fff" }} component="body" dangerouslySetInnerHTML={{ __html: currentOrderData }} />
            </Box>
        </RightPanel>
    )
}
