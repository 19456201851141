import React, { useState } from 'react'
import { IOrder } from '../../../../../../redux/orders';
import { Box, DialogTitle, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { BiSolidDollarCircle } from 'react-icons/bi';
import { Dialog } from '../../../../../../Components/Dialogs/Dialog';
import { FaCreditCard, FaWallet } from 'react-icons/fa';
import { CustomRoundButton } from '../../../../../../Components/CustomButton';
import { CashPaymentScreen } from './CashPaymentScreen';
import CloseIcon from '@mui/icons-material/Close';
import { CardPaymentScreen } from './CardPaymentScreen';
import { PrintOrderReceiptDialog } from '../PrintOrderReceiptDialog/PrintOrderReceiptDialog';

const orderPaymentType = [
    { title: "Cash", value: "CASH", icon: <BiSolidDollarCircle fontSize={"2rem"} /> },
    { title: "Card", value: "CARD", icon: <FaCreditCard fontSize={"2rem"} /> },
    { title: "E-Wallet", value: "E_WALLET", icon: <FaWallet fontSize={"2rem"} /> }
]

export interface IOrderPaymentDialogProps {
    open: boolean;
    orderInfo: IOrder;
    onClose: () => void;
    onPaymentSuccess: () => void;
}

export const OrderPaymentDialog: React.FC<IOrderPaymentDialogProps> = ({
    open,
    orderInfo,
    onClose,
    onPaymentSuccess
}) => {
    const [orderPaymentMode, setOrderPaymentMode] = useState<"CASH" | "CARD" | "E_WALLET">("CASH")
    const [openPrintDialog, setOpenPrintDialog] = React.useState<IOrder | null>(null);



    const handlePaymentSuccess = (data: IOrder) => {
        setOpenPrintDialog(data)
    }

    const handleClosePrintDialog = () => {
        setOpenPrintDialog(null)
        onClose()
    }

    return (
        <Dialog
            open={open}
            title="Payment"
            size="sm"
            // contentWrappedWithForm={{ onSubmit: handleSubmit }}
            onClose={onClose}
            contentSx={{
                padding: 0,
                minHeight: 550,
                background: "#171717"
            }}
            hideHeader
        >
            <DialogTitle fontSize={18} fontWeight={700} sx={{ m: 0, p: 2, background: "#0f0f0f" }} id="customized-dialog-title">
                Pay Now
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>
            <Grid container >
                <Grid item xs={12} md={3} sx={{ borderRight: "1px solid #606060", minHeight: 550 }}>
                    <List sx={{ padding: 0 }}>
                        {orderPaymentType.map((l_item, l_idx) => {
                            return (
                                <ListItem disablePadding sx={{ background: l_item.value === orderPaymentMode ? "#606060" : "#303030", mb: .25 }} onClick={() => setOrderPaymentMode(l_item.value as "CASH")}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {l_item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={l_item.title} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                </Grid>
                <Grid item xs={12} md={9}>
                    {orderPaymentMode === "CASH" ?
                        <CashPaymentScreen orderInfo={orderInfo} onSuccess={handlePaymentSuccess} />
                        : <CardPaymentScreen orderInfo={orderInfo} onClose={onClose} />
                    }

                </Grid>
            </Grid>
            {/* <Stack direction="row" width="100%" spacing={2} px={2}>
                <CustomRoundButton
                    title="Cancel"
                    type="submit"
                    // disabled={Number(values.cash_received) < Number(values.total_amount)}
                    onClick={onClose}
                    fullWidth />
            </Stack> */}
             {openPrintDialog && <PrintOrderReceiptDialog
                open={true}
                data={{
                   order_uuid: openPrintDialog.order_uuid || "",
                   outlet_uuid: openPrintDialog.outlet_uuid || "",
                //    order_type: openPrintDialog.order_type === "DINE_IN" ? "ORDER" : "TAKEOUT"
                   order_type: "ORDER"
                }}
                onClose={handleClosePrintDialog}
            />}
        </Dialog>
    )
}
