import React from "react";
// import { DataTable, ITableColumn } from "../../../Components/Tables/Table";
import { Avatar, Typography } from "@mui/material";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../Components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../Components/Table/DataTable";
import { useDateFilter } from "../../../Components/Table/hooks/useDateFilter";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { ICategory, clearCategoriesStateSync, fetchCategoriesListWithArgsAsync } from "../../../redux/inventory";
import { useNavigate } from "react-router-dom";
import { StandardTableActions } from "../../../Components/StandardTableActions/StandardTableActions";
import { formatDateWithTime } from "../../../helpers";
import { UpsertCategoryDialog } from "./dialogs/UpsertCategoryDialog";

export const CategoriesTable = () => {
  const {
    data: categoriesInfo,
    loading,
  } = useAppSelector((storeState: IStoreState) => storeState.inventory.categories.category_list);

  const [pagination, setPagination] = React.useState({
    pageNumber: 1,
    rowsPerPage: 10,
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openUpsertDialog, setOpenUpsertDialog] = React.useState<ICategory | null>(null);


  const dataTableProps: IDataTableProps = {
    isPagination: true,
    rowsPerPageOptions: 25,
    isDataLoading: false,
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    initialSortConfig: {
      insert_ts: {
        direction: "desc",
        filedName: "insert_ts",
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICategory) => {
          return (
            <StandardTableActions
              onEditClick={() => setOpenUpsertDialog(row)}
            />
          );
        },
      },
      {
        key: "category_name",
        headerName: "Category Name",
        fieldName: "category_name",
        enableSorting: true,
        renderType: RenderType.TEXT,
        onRowCellRender: (value, row: ICategory) => {
          return (
            <Typography variant="h6" fontWeight={"bold"} letterSpacing={-0.5}>
              {row.category_name}
            </Typography>
          );
        },

      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        enableSorting: true,
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "insert_ts",
        headerName: "Date",
        fieldName: "insert_ts",
        enableSorting: true,
        renderType: RenderType.DATE_DARK_COLOR,
        exportCellWidth: 25,
        headingAlign: "center",
        onExportRender: (values) => {
          return formatDateWithTime(values.value);
        },
      },
    ],

    items: categoriesInfo,
    onPageChange: (newPageNumber: number) => {
      setPagination({ ...pagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setPagination({
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
      });
    },
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCategoriesStateSync());
    };
  }, []);

  const fetchList = () => {
    dispatch(
      fetchCategoriesListWithArgsAsync({
        page: pagination.pageNumber,
        rowsPerPage: pagination.rowsPerPage,
      }),
    );
  };

  console.log("categoriesInfo ==>", categoriesInfo)
  return (
    <>
      <DataTable {...dataTableProps} />
      {openUpsertDialog && <UpsertCategoryDialog open={true} category={openUpsertDialog} onClose={() => setOpenUpsertDialog(null)} />}
    </>
  );
};
