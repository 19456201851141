/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { StandardCard } from "../../../../Components/Card";
import PageContainer from "../../../../Components/PageContainer/PageContainer";
import { StandardTableActions } from "../../../../Components/StandardTableActions/StandardTableActions";
import { DataTable } from "../../../../Components/Table/DataTable";
import { IDataTableProps, RenderType } from "../../../../Components/Table/interfaces/IDataTableProps";
import { RefreshPlugin } from "../../../../Components/Table/plugins";
import { LoadState } from "../../../../constants/enum";
import { formatDateWithTime } from "../../../../helpers";
import { Breadcrumb } from "../../../../layout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../../../layout/breadcrumb/IBreadcrumbProps";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../../redux";
import { ISecurityRoleGroup, clearSecurityRoleGroupListSync, defaultSecurityRoleGroup, fetchSecurityRoleGroupListAsync } from "../../../../redux/settings/security";
import { ManageRoleGroupDialog } from "./ManageRoleGroupDialog";
import { StatusRenderer } from "../../../../Components/ActiveInActiveStatus/ActiveInActiveStatus";
import { CustomButton } from "../../../../Components/CustomButton";

export const RoleGroupTable: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "role-groups",
    },
  ];

  const { data: roleGroupsList, loading, totalRecords } = useAppSelector(
    (storeState: IStoreState) => storeState.settings.security.roleGroups,
  );
  const dispatch = useAppDispatch();

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 50,
  });
  const [selectedRoleGroup, setSelectedRoleGroup] =
    React.useState<ISecurityRoleGroup | null>(null);

  const roleGroupsTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading !== LoadState.succeeded,
    selectionMode: "none",
    uniqueRowKeyName: "id",
    columns: [
      {
        key: "role_group",
        headerName: "Role Group",
        fieldName: "role_group",
        enableSorting: true,
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 30,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "create_ts",
        headerName: "Date",
        fieldName: "create_ts",
        enableSorting: true,
        renderType: RenderType.DATE,
        exportCellWidth: 25,
        onExportRender: (values) => {
          return formatDateWithTime(values.value);
        },
      },
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: any) => {
          const { rowId, ...restData } = row
          return (
            <StandardTableActions
              onEditClick={() => setSelectedRoleGroup(restData)}
            />
          );
        },
      },
    ],
    items: roleGroupsList,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };

  React.useEffect(() => {
    dispatch(
      fetchSecurityRoleGroupListAsync({
        page: tablePagination.pageNumber,
        rowsPerPage: tablePagination.rowsInPerPage,
      }),
    );
  }, [tablePagination]);

  React.useEffect(() => {
    return () => {
      dispatch(clearSecurityRoleGroupListSync());
    };
  }, []);

  return (
    <PageContainer title="Role Groups" description="this is inner page">
      {/* <Breadcrumb title="View Role Groups" items={BCrumb} /> */}
      <Stack direction="row" justifyContent="space-between" alignItems="center" my={2} >
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <Typography variant='h2'>Role Groups</Typography>
        </Stack>
        <CustomButton
          variant="contained"
          color="primary"
          sx={{
            display: "flex",
            marginLeft: "auto",
          }}
          onClick={() => setSelectedRoleGroup(defaultSecurityRoleGroup)}
        >
          Create Role Group
        </CustomButton>
      </Stack>

      <DataTable {...roleGroupsTableProps} />

      {selectedRoleGroup && (
        <ManageRoleGroupDialog
          open={true}
          roleGroup={selectedRoleGroup}
          onClose={() => setSelectedRoleGroup(null)}
        />
      )}
    </PageContainer>
  );
};
