import React from "react";
import { useSelector } from "react-redux";
import { Alert, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingDialog from "../Dialogs/LoadingDialog";
import { IStoreState, useAppSelector } from "../../redux";

export const MessageBox: React.FC = () => {
  const { loader_with_message } = useAppSelector((storeState: IStoreState) => storeState.common);


  // const handleClearMessage = () => {
  //   dispatch(hideMessage());
  // };

  // if (item) {
  //   if (item.displayAs === "snackbar") {
  //     return (
  //       <Snackbar
  //         anchorOrigin={{ vertical: "top", horizontal: "right" }}
  //         open={true}
  //         autoHideDuration={5000}
  //         onClose={handleClearMessage}
  //         key={"topright"}
  //       >
  //         <Alert
  //           severity={item.type}
  //           action={
  //             <IconButton
  //               aria-label="close"
  //               color="inherit"
  //               size="small"
  //               onClick={handleClearMessage}
  //             >
  //               <CloseIcon fontSize="inherit" />
  //             </IconButton>
  //           }
  //         >
  //           {item.message}
  //         </Alert>
  //       </Snackbar>
  //     );
  //   }
  // }
  // if (saveLoader) {
  //   return <LoadingDialog open={saveLoader} />;
  // }

  if (loader_with_message.loading) {
    return (
      <LoadingDialog
        open={loader_with_message.loading}
        label={loader_with_message.message || "Loading"}
      />
    );
  }
  return <></>;
};
