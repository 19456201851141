import { LoadState } from "../../../constants/enum";
import { IReceiptComposer, IReceiptComposerOrderItem, IReceiptComposerState } from "./receipt-composer.types";

export const defaultReceiptComposerOrderItem: IReceiptComposerOrderItem = {
    column_title: "",
    column_name: "",
    column_width: "0",
    column_value: ""
}
export const defaultReceiptComposer: IReceiptComposer = {
    receipt_composer_uuid: null,
    receipt_name: "",
    receipt_logo: "",
    receipt_type: "ORDER",
    receipt_header: "",
    receipt_footer: "",
    page_size: 3.145,
    padding_top: 0,
    padding_bottom: 0,
    padding_left: 0,
    padding_right: 0,
    outlet_uuid: "",
    items_font_size: 12,
    order_details: [
        {...defaultReceiptComposerOrderItem, column_width: "10"},
        {...defaultReceiptComposerOrderItem, column_width: "65"},
        {...defaultReceiptComposerOrderItem, column_width: "10"},
        {...defaultReceiptComposerOrderItem, column_width: "15"},
    ]
}


export const defaultReceiptComposerState: IReceiptComposerState = {
    receipts_list: {
        loading: LoadState.idle,
        data: [],
        count: 0,
        error: null,
    },
    single_receipt: {
        loading: LoadState.idle,
        data: defaultReceiptComposer,
        error: null
    }
}