import React, { useEffect } from 'react'
import PageContainer from '../../../Components/PageContainer/PageContainer'
import { StandardCard } from '../../../Components/Card'
import { QuillEditor } from '../../../Components/QuillEditor/QuillEditor'
import { useFormik } from 'formik'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../redux'
import { clearSingleOutletReceiptsSync, defaultReceiptComposerOrderItem, fetchSingleOutletReceiptInfoAsync, upsertSingleOutletReceiptAsync } from '../../../redux/settings/receipt-composer'
import { Box, Button, Grid, InputAdornment, Stack, Typography } from '@mui/material'
import { ControlledCustomSelect, OutlinedTextField } from '../../../Components/formComponents'
import { useNavigate, useParams } from 'react-router-dom'
import { RECEIPT_COMPOSER_ROUTE } from './ReceiptComposer.constants'
import { ProfileIntroCard } from '../../../Components/Profile/ProfileIntoCard'
import { OutletsDropDown } from '../CompanyOutlets/components/OutletsDropDown'
import { ReceiptOutput } from './components/ReceiptOutput'
import { OutletColumnsButton } from './components/OutletColumnsButton/OutletColumnsButton'
import { ReceiptOrderDetails } from './components/ReceiptOrderDetails/ReceiptOrderDetails'

export const ManageSingleReceipt = () => {
  const { uuid: receiptUUID } = useParams<{ uuid?: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: receiptComposerInfo } = useAppSelector((storeState: IStoreState) => storeState.settings.receiptComposer.single_receipt);
  const [fileToUpload, setFileToUpload] = React.useState<File | null>(null);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...receiptComposerInfo,
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.receipt_name) {
        errors.receipt_name = "*This Field is required.";
      }
      if (!values.outlet_uuid) {
        errors.outlet_uuid = "*This Field is required.";
      }
      if (!values.receipt_header) {
        errors.receipt_header = "*This Field is required.";
      }
      if (!values.receipt_footer) {
        errors.receipt_footer = "*This Field is required";
      }
      if (!values.page_size) {
        errors.page_size = "*This Field is required";
      }
      if (!values.padding_top) {
        errors.padding_top = "*This Field is required";
      }
      if (!values.padding_bottom) {
        errors.padding_bottom = "*This Field is required";
      }
      if (!values.padding_left) {
        errors.padding_left = "*This Field is required";
      }
      if (!values.padding_right) {
        errors.padding_right = "*This Field is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      dispatch(upsertSingleOutletReceiptAsync({ ...values, file: fileToUpload })).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          navigate(RECEIPT_COMPOSER_ROUTE)
        }
      })
    },
  });


  useEffect(() => {
    if (!receiptUUID) return
    dispatch(fetchSingleOutletReceiptInfoAsync(receiptUUID))
  }, [receiptUUID])

  useEffect(() => {
    setValues(receiptComposerInfo)
  }, [receiptComposerInfo])

  React.useEffect(() => {
    return () => {
      dispatch(clearSingleOutletReceiptsSync());
    };
  }, []);


  return (
    <PageContainer title='receipt-composer' description='' >
      <Box component="form" onSubmit={handleSubmit} mb={3}>
        <Typography variant='h2'>Receipt Composer</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} >
            <Box sx={{ height: "80vh", overflowY: "scroll", pr: 2 }}>
              <Box sx={{ paddingBottom: 2 }}>
                <StandardCard
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    mt: 2
                  }}
                >
                  <ProfileIntroCard
                    fileUrl={values.receipt_logo}
                    title="Receipt Logo"
                    subTitle="Change Receipt logo from here"
                    onChange={(file) => setFileToUpload(file)}
                    onFileReset={() => setFieldValue("receipt_logo", null)}

                  />
                </StandardCard>

                <StandardCard heading='Receipt Information' sx={{ mt: 2 }}>
                  <Grid container spacing={2}>

                    <Grid item xs={12} md={12}>
                      <OutlinedTextField
                        label="Receipt Name"
                        id="receipt_name"
                        value={values.receipt_name}
                        onChange={handleChange}
                        error={errors.receipt_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <OutletsDropDown
                        value={values.outlet_uuid}
                        onChange={(newValue) => setFieldValue("outlet_uuid", newValue)}
                        error={errors.outlet_uuid}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <ControlledCustomSelect
                        label="Receipt Type"
                        name="receipt_type"
                        fullWidth
                        options={[
                          { value: "KITCHEN", label: "KITCHEN" },
                          { value: "TAKEOUT", label: "TAKEOUT" },
                          { value: "REPORT", label: "REPORT" },
                          { value: "ORDER", label: "ORDER" },
                        ]}
                        placeholder="Receipt Type"
                        value={values.receipt_type}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </StandardCard>

                <StandardCard heading="Page Information" sx={{ mt: 2 }}>
                  <Grid container spacing={2}>


                    <Grid item xs={12} md={6}>
                      <OutlinedTextField
                        label="Page Size"
                        id="page_size"
                        type="number"
                        disabled
                        value={values.page_size}
                        onChange={handleChange}
                        error={errors.page_size}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">inch</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <OutlinedTextField
                        label="Items Font Size"
                        id="items_font_size"
                        type="number"
                        value={values.items_font_size}
                        onChange={handleChange}
                        error={errors.items_font_size}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">px</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} />

                    <Grid item xs={12} md={3}>
                      <OutlinedTextField
                        label="Padding Top"
                        id="padding_top"
                        type="number"
                        value={values.padding_top}
                        onChange={handleChange}
                        error={errors.padding_top}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <OutlinedTextField
                        label="Padding Bottom"
                        id="padding_bottom"
                        type="number"
                        value={values.padding_bottom}
                        onChange={handleChange}
                        error={errors.padding_bottom}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <OutlinedTextField
                        label="Padding Left"
                        id="padding_left"
                        type="number"
                        value={values.padding_left}
                        onChange={handleChange}
                        error={errors.padding_left}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <OutlinedTextField
                        label="Padding Right"
                        id="padding_right"
                        type="number"
                        value={values.padding_right}
                        onChange={handleChange}
                        error={errors.padding_right}
                      />
                    </Grid>

                  </Grid>
                </StandardCard>
              </Box>

              <StandardCard heading="Header" headingAlignment='flex-start' sx={{ mt: 2 }}
                rightHeading={
                  <OutletColumnsButton />
                }
              >
                <QuillEditor
                  value={values.receipt_header}
                  onChange={(html) => setFieldValue("receipt_header", html)}
                  error={errors.receipt_header ? true : false}
                />

              </StandardCard>

              <StandardCard heading="Order Information" sx={{ mt: 2 }}>
                <ReceiptOrderDetails data={values.order_details.length > 0 ? values.order_details : [defaultReceiptComposerOrderItem]} onChange={(data) => setFieldValue("order_details", data)} />

              </StandardCard>


              <StandardCard heading="Footer" sx={{ mt: 2 }}>
                <QuillEditor
                  value={values.receipt_footer}
                  onChange={(html, delta, source, editor) => {
                    console.log("html, delta, source, editor ===>", html, delta, source, editor)
                    setFieldValue("receipt_footer", html)
                  }}
                  error={errors.receipt_footer ? true : false}
                />

              </StandardCard>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <StandardCard heading="Output" sx={{ mt: 2 }}>
              <ReceiptOutput data={values} file={fileToUpload} />
            </StandardCard>
          </Grid>
        </Grid>


        <Box sx={{ mt: 4 }}>
          <Button
            sx={{ width: "15%" }}
            // disabled={saveLoading}
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Box>
      </Box>

    </PageContainer>
  )
}
