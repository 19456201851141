import React from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  InputAdornment
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper/Paper";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { CustomAlert, CustomTextFieldV2 } from "../../Components/formComponents";
import { useAuthProvider } from "../../providers";
import { useFormik } from "formik";
import PersonIcon from '@mui/icons-material/Person';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { DASHBOARD_ROUTES } from "../Dashboard/Dashboard.constants";
import { APP_DASHBOARD_V2_ROUTE } from "../DashboardV2/DashboardV2.contants";


export const LoginPage: React.FC = () => {
  const { isLoggedIn, loginAsync } = useAuthProvider();
  const navigate = useNavigate();
  const [step, setStep] = React.useState<"login" | "otp">("login");
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const location = useLocation();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      otp: "",
    },
    validate: (values) => {
      let errors: any = {}
      if (!values.email) {
        errors.email = "This is required field."
      }
      if (!values.password) {
        errors.password = "This is required field."
      }
      return errors
    },
    onSubmit: async (values) => {
      setLoading(true);
      if (step === "login") {
        await loginAsync(
          values.email,
          values.password,
          (isOTPPreview) => {
            if (isOTPPreview) {
              setLoading(false);
              setStep("otp");
            } else {
              const state: any = location.state;
              navigate(state ? state.path : APP_DASHBOARD_V2_ROUTE);
            }
          },
          (errorMessage) => {
            setLoading(false);
            setError(errorMessage);
          },
        );
      } else {
        // await verifyOTP(
        //   values.otp,
        //   () => {
        //     const state: any = location.state;
        //     setLoading(false);
        //     navigate(state ? state.path : "/dashbaord");
        //   },
        //   (errorMessage) => {
        //     setLoading(false);
        //     setError(errorMessage);
        //   },
        // );
      }
    },
  });

  const emailError = formik.errors.email ? true : false;
  const passwordError = formik.errors.password ? true : false;
  const otpError = formik.errors.otp ? true : false;


  const handleResendOtpClick = () => {

  }

  return (
    <PageContainer title="Login" description="this is Login page">
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            p: 4,
            pt: 5,
            pb: 5,
            border: "none rgb(230, 235, 241)",
            borderRadius: "4px",
            boxShadow: "rgb(0 0 0 / 8%) 0px 1px 4px",
            background: "#fff",
            maxWidth: 400
          }}
        >
          <Typography variant="h2" fontWeight={"bold"} align="center" sx={{ color: "#000" }} >
            {step === "login" ? "Login" : "Authentication required"}
          </Typography>

          {error && (
            <CustomAlert
              sx={{ mt: 2 }}
              severity="error"
              onClose={() => setError(null)}
            >
              {error}
            </CustomAlert>
          )}
          <form onSubmit={formik.handleSubmit}>
            {step === "login" && (
              <>
                <FormControl sx={{ mt: 2 }} fullWidth error={emailError}>
                  <CustomTextFieldV2
                    id="email"
                    variant="outlined"
                    placeholder="enter email address"
                    value={formik.values.email}
                    fullWidth
                    error={emailError}
                    helperText={formik.errors.email}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment sx={{ background: "#252525" }} position="end"><PersonIcon /></InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl sx={{ mt: 2 }} fullWidth error={passwordError}>
                  <CustomTextFieldV2
                    id="password"
                    variant="outlined"
                    placeholder="enter password"
                    fullWidth
                    value={formik.values.password}
                    type="password"
                    error={passwordError}
                    helperText={formik.errors.password}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment sx={{ background: "#252525" }} position="end"><LockOpenIcon /></InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Box sx={{ mt: 2 }} display="flex" justifyContent={"end"}>
                  <Typography
                    component={Link}
                    to="/auth/forget-password"
                    fontWeight="500"
                    sx={{
                      display: "block",
                      textDecoration: "none",
                      mb: "16px",
                      mt: 2,
                      color: "#000",

                    }}
                  >
                    Forgot Password ?
                  </Typography>
                </Box>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled={loading}
                  type="submit"
                  sx={{
                    mt: 2,
                    pt: "10px",
                    pb: "10px",
                    background: "#000",
                    color: "#fff",
                    "&:hover": {
                      background: "#252525",
                      color: "#fff",
                    }
                  }}
                >
                  Login
                </Button>
              </>
            )}
            {step === "otp" && (
              <>
                <FormControl sx={{ mt: 2 }} fullWidth error={otpError}>
                  <Box display="flex" justifyContent={"end"}>
                    <Typography
                      fontWeight="600"
                      sx={{
                        display: "block",
                        textDecoration: "none",
                        mb: 2,
                        color: "#000"
                      }}
                    >
                      We’ve sent a One Time Password (OTP) to the email.
                      Please enter it to complete verification
                    </Typography>
                  </Box>
                  <CustomTextFieldV2
                    label="OTP"
                    id="otp"
                    variant="outlined"
                    fullWidth
                    value={formik.values.otp}
                    type="number"
                    error={otpError}
                    helperText={formik.errors.otp}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <Typography
                  fontWeight="600"
                  sx={{
                    display: "block",
                    textDecoration: "none",
                    mb: "16px",
                    color: "#000",
                    cursor: "pointer"
                  }}
                  onClick={handleResendOtpClick}
                >
                  Resend otp
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled={loading}
                  type="submit"
                  sx={{
                    mt: 2,
                    pt: "10px",
                    pb: "10px",
                    background: "#000",
                    color: "#fff",
                    "&:hover": {
                      background: "#252525",
                      color: "#fff",
                    }
                  }}
                >
                  Verify
                </Button>
              </>
            )}
          </form>
        </Paper>
      </Box>
    </PageContainer>
  );
};