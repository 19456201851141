import { isNotNull } from "../../helpers/isNotNull";
import { IOrder } from "./orders.types";



export const calculateOrderItemCalculations = (values: IOrder): IOrder => {
    let subTotal = 0;
    let totalTax = 0;
    values.menu_items.forEach((item) => {
        const currentSubTotal = isNotNull(item.product_price)
        totalTax += (currentSubTotal * isNotNull(item.tax_percentage)) / 100;
        subTotal += currentSubTotal
        item.options.forEach((option) => {
            const optionSubTotal = isNotNull(option.modifier_price)
            totalTax += (optionSubTotal * isNotNull(option.tax_percentage)) / 100;
            subTotal += optionSubTotal
        });
    });

    return {
        ...values,
        sub_total: subTotal,
        total_tax: Number(totalTax.toFixed(4)),
        total_amount: (subTotal + totalTax)
    }
}