import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../../api/axios-api"
import { IQueryParams, getSearchQuery } from "../../../common"
import { ICompanyOutlet } from "./company-outlets.types"
import { defaultOutlet } from "./company-outlets.state"
import { uploadSingleFileAsync } from "../../../helpers/uploadFilesAsync"
import { closeLoaderWithMessage, openLoaderWithMessage } from "../../common"

export const GET_OUTLET_BASE_URL = "/company/get-outlet"
const UPSERT_OUTLET_BASE_URL = "/company/upsert-outlet"


export const fetchOutletsListAsync = createAsyncThunk<{ data: ICompanyOutlet[], count: number }, IQueryParams>(
    'outlets/fetchOutletsListAsync', async (queryParams: IQueryParams) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_OUTLET_BASE_URL}${searchQuery}`)
        return response.data as { data: ICompanyOutlet[], count: number }
    },
)

export const fetchSingleOutletAsync = createAsyncThunk<ICompanyOutlet, string>(
    'outlets/fetchSingleOutletAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`${GET_OUTLET_BASE_URL}?outlet_uuid=${uuid}`)
        return response.data?.data?.length > 0 ? response.data?.data[0] : defaultOutlet
    },
)

interface IUpsertCompanyOutlet extends ICompanyOutlet {
    file: File | null,
}

export const upsertCompanyOutletAsync = createAsyncThunk<ICompanyOutlet, IUpsertCompanyOutlet>(
    'outlets/upsertCompanyOutletAsync', async (payload: IUpsertCompanyOutlet, thunkAPI) => {
        const { file, create_ts, insert_ts, ...restPayload } = payload
        thunkAPI.dispatch(openLoaderWithMessage("Saving Changes..!"))
        if (file) {
            const path = await uploadSingleFileAsync({
                file,
                moduleName: "INVENTORY",
                asPayload: {
                    outlet_uuid: payload.outlet_uuid
                }
            })
            restPayload.outlet_logo = path
        }
        const response = await axios_api.post(UPSERT_OUTLET_BASE_URL, restPayload)
        thunkAPI.dispatch(closeLoaderWithMessage())
        return response.data
    },
)


