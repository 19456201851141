import React, { useEffect } from 'react'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../../../redux';
import { Delete } from '@mui/icons-material';
import { Box, Typography, Grid, Button, IconButton } from '@mui/material';
import { useFormik } from 'formik';
import { OutlinedTextField } from '../../../../../../Components/formComponents';
import { IOrderDiscountCoupon, validateSingleCouponCodeAsync, applyCouponCodeToOrderSync, removeCouponCodeFromOrderSync, addExtraChargesFieldInOrderSync, removeExtraChargesFieldFromOrderSync, applyExtraCharesToOrderSync } from '../../../../../../redux/orders';

export const OtherChargesScreen = () => {
    const dispatch = useAppDispatch();
    const { data: currentOrder } = useAppSelector((storeState: IStoreState) => storeState.orders.single_order);

    const { extra_charges } = currentOrder
    if (!extra_charges) return <></>

    const addNewExtraChargesField = () => {
        dispatch(addExtraChargesFieldInOrderSync())
    }


    return (
        <Box padding={2}>
            <Typography variant="h2" mb={2} fontWeight={"bold"}>Other Charges</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    {extra_charges && extra_charges.length > 0 && extra_charges.map((coupon, c_idx) => {
                        return <SingleChargesField charges={coupon} index={c_idx} disabled={extra_charges.length === 1} />
                    })}
                </Grid>
                <Grid item xs={12} md={12}>
                    < Button variant='contained' onClick={addNewExtraChargesField} >Add New Field</Button >
                </Grid>
            </Grid >
        </Box>
    )
}



export const SingleChargesField: React.FC<{
    charges: { title: string, amount: string },
    index: number,
    disabled: boolean
}> = ({ charges, index, disabled }) => {
    const dispatch = useAppDispatch();

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues,
        setFieldValue,
        isValid,
        resetForm,
        setFieldError
    } = useFormik({
        initialValues: {
            ...charges,
        },
        validate: (values) => {
            const errors: any = {};
            // if (values.recipient_emails.length <= 0) {
            //   errors.recipient_emails = "*Email is required.";
            // }
            // if (!values.subject) {
            //   errors.subject = "*This Field is required";
            // }
            // if (!values.body) {
            //   errors.body = "*This Field is required";
            // }
            return errors;
        },
        onSubmit: async (values) => {
            dispatch(applyExtraCharesToOrderSync({
                charges: values, index
            }))
        },
    });

    useEffect(() => {
        setValues(charges)
    }, [charges])


    const handleDeleteCouponCode = () => {
        dispatch(removeExtraChargesFieldFromOrderSync(index))
    }


    return (
        <Grid container component={"form"} onSubmit={handleSubmit} spacing={2} mb={1}>
            <Grid item xs={12} sm={1}>
                <IconButton onClick={handleDeleteCouponCode} disabled={disabled}>
                    <Delete />
                </IconButton>
            </Grid>

            <Grid item xs={12} sm={5}>
                <OutlinedTextField
                    label="Title"
                    id="title"
                    fullWidth={false}
                    value={values.title}
                    onChange={handleChange}
                    error={errors.title}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <OutlinedTextField
                    label="Amount"
                    id="amount"
                    fullWidth={false}
                    value={values.amount}
                    onChange={handleChange}
                    error={errors.amount}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Button sx={{ px: 2 }} variant='contained' type="submit">Save</Button>
            </Grid>
        </Grid>
    )
}