import { LoadState } from "../../../constants/enum";
import { ICreateUser, IUserProfile, IUserProfileState } from "./user-profile.types";



export const defaultCreateUser: ICreateUser = {
    first_name: "",
    last_name: "",
    email: "",
    user_password: "",
    confirmPassword: "",
    outlet_uuid: "",
    outlet_name: "",
    status: "ACTIVE",
    role_uuid: "",
  };

export const defaultUserProfile: IUserProfile = {
    user_uuid: null,

    first_name: "",
    last_name: null,
    email: "",
    phone_number: null,
    date_of_birth: null,
    profile_picture: null,
    gender: null,
    login_pin: null,
    user_address_line1: null,
    user_address_line2: null,
    user_address_city: null,
    user_address_state: null,
    user_address_pin_code: null,
    user_address_country: null,

    store_location: null,
    role: null,
    salary_type: "PAY_ROLE",
    pay_role_type: "WEEKLY",
    hourly_wage: null,
    monthly_salary: null,
    documents: [],
    status: "ACTIVE"
};

export const defaultUserProfileState: IUserProfileState = {
    users_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_user: {
        loading: LoadState.idle,
        data: defaultUserProfile,
        error: null,
    }
};
