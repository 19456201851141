import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { OutlinedTextField } from '../../../../../../Components/formComponents'
import { addCouponCodeItemInOrderSync, applyCouponCodeToOrderSync, IOrder, IOrderDiscountCoupon, removeCouponCodeFromOrderSync, validateSingleCouponCodeAsync } from '../../../../../../redux/orders'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../../../redux'
import { Delete } from '@mui/icons-material'
import { useFormik } from 'formik'

interface IDiscountCouponsScreenProps {
}
export const DiscountCouponsScreen: React.FC<IDiscountCouponsScreenProps> = () => {
  const dispatch = useAppDispatch();
  const { data: currentOrder } = useAppSelector((storeState: IStoreState) => storeState.orders.single_order);

  const { discount_coupons } = currentOrder

  if(!discount_coupons) return <></>
  
  const addNewDiscountCouponCodeField = () => {
    dispatch(addCouponCodeItemInOrderSync())
  }


  return (
    <Box padding={2}>
      <Typography variant="h2" mb={2} fontWeight={"bold"}>Discount Coupons</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          {discount_coupons && discount_coupons.length > 0 && discount_coupons.map((coupon, c_idx) => {
            return <SingleCouponCodeField coupon={coupon} index={c_idx} disabled={discount_coupons.length === 1} />
          })}
        </Grid>
        <Grid item xs={12} md={12}>
          < Button variant='contained' onClick={addNewDiscountCouponCodeField} >Add New Coupon</Button >
        </Grid>
      </Grid >
    </Box>
  )
}




export const SingleCouponCodeField: React.FC<{
  coupon: IOrderDiscountCoupon,
  index: number,
  disabled: boolean
}> = ({ coupon, index, disabled }) => {
  const dispatch = useAppDispatch();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    isValid,
    resetForm,
    setFieldError
  } = useFormik({
    initialValues: {
      ...coupon,
    },
    validate: (values) => {
      const errors: any = {};
      // if (values.recipient_emails.length <= 0) {
      //   errors.recipient_emails = "*Email is required.";
      // }
      // if (!values.subject) {
      //   errors.subject = "*This Field is required";
      // }
      // if (!values.body) {
      //   errors.body = "*This Field is required";
      // }
      return errors;
    },
    onSubmit: async (values) => {
      dispatch(validateSingleCouponCodeAsync({
        coupon_code: values.coupon_code, onCallback(isSuccess, data) {
          if (isSuccess && data) {
            console.log("isSuccess && data ===>", data)
            dispatch(applyCouponCodeToOrderSync({
              coupon: {
                ...values,
                amount: data.coupon_amount,
                is_valid: true,
                is_validated: true
              }, index
            }))
          } else {
            // dispatch(applyCouponCodeToOrderSync({
            //   coupon: {
            //     ...values,
            //     is_valid: false,
            //     is_validated: true
            //   }, index
            // }))
            setFieldError("coupon_code", "Invalid coupon Code")
          }

        }
      }))
    },
  });

  useEffect(() => {
    setValues(coupon)
  }, [coupon])


  const handleDeleteCouponCode = () => {
    dispatch(removeCouponCodeFromOrderSync(index))
  }


  return (
    <Grid container component={"form"} onSubmit={handleSubmit} spacing={2} mb={1}>
      <Grid item xs={12} sm={1}>
        <IconButton onClick={handleDeleteCouponCode} disabled={disabled}>
          <Delete />
        </IconButton>
      </Grid>

      <Grid item xs={12} sm={5}>
        <OutlinedTextField
          label="Coupon Code"
          id="coupon_code"
          fullWidth={false}
          disabled={values.is_valid}
          value={values.coupon_code}
          onChange={handleChange}
          error={errors.coupon_code}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Button variant='contained' disabled={values.is_valid} type="submit">{values.is_valid ? "Applied" : "Apply"}</Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <OutlinedTextField
          label="Amount"
          id="amount"
          disabled
          value={values.amount}
        />
      </Grid>
    </Grid>
  )
}