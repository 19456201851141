import type { BoxProps } from '@mui/material/Box';
import type { CardProps } from '@mui/material/Card';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import ListItemText from '@mui/material/ListItemText';

import { Iconify } from '../components/iconify';
import { Scrollbar } from '../components/scrollbar';
import { Typography } from '@mui/material';
import { IStoreState, useAppDispatch, useAppSelector } from '../../../redux';
import React from 'react';
import { fetchAllUsersProfileAsync } from '../../../redux/settings/user-profile/user-profile.actions';
import { clearAllUsersStateSync, IUserProfile } from '../../../redux/settings/user-profile';
import { ALL_USERS_LIST_ROUTE } from '../../Settings/UserProfile/UserProfile.constant';
import { useNavigate } from 'react-router-dom';
import { clearOrdersStateSync, fetchOrdersListAsync, fetchOrdersListWithArgsAsync, IOrder } from '../../../redux/orders';
import { POS_APP_MENU_ROUTE_NEW_ORDER, POS_ORDERS_ROUTE } from '../../MenuAndOrders/MenuAndOrders.constants';

// ----------------------------------------------------------------------

type Props = CardProps & {
    title?: string;
    subheader?: string;
    list: {
        id: string;
        name: string;
        email: string;
        avatarUrl: string;
    }[];
};

export function QuickOrdersVIew() {
    const {
        data: allOrders,
        loading,
        totalRecords
    } = useAppSelector((storeState: IStoreState) => storeState.orders.orders_list);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(
            fetchOrdersListWithArgsAsync({
                page: 1,
                rowsPerPage: 5,
            }),
        );

        return () => {
            dispatch(clearOrdersStateSync());
        };
    }, []);



    const handleOnViewAllUsersClick = () => {
        navigate(`${POS_APP_MENU_ROUTE_NEW_ORDER}?type=TAKE_AWAY`)
    }

    return (
        <Card>
            <CardHeader
                title={"Take Away Orders"}
                titleTypographyProps={{
                    variant: "h3"
                }}
                // subheader={`You have ${totalRecords} Users`}
                action={
                    <Button
                        size="small"
                        color="inherit"
                        onClick={handleOnViewAllUsersClick}
                        endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
                    >
                        Order Now
                    </Button>
                }
            />

            <Box sx={{ gap: 2, px: 3,  display: 'flex', alignItems: 'center', justifyContent: "space-between" }} >
                <Typography variant='h5'>Order Number</Typography>
                <Typography variant='h5'>Sub Total</Typography>
                <Typography variant='h5'>Total</Typography>
            </Box>

            <Scrollbar sx={{ minHeight: 200 }}>
                <Box
                    sx={{
                        p: 3,
                        gap: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 200,
                    }}
                >
                    {allOrders.slice(0, 5).map((item) => (
                        <Item key={item.order_uuid} item={item} />
                    ))}
                </Box>
            </Scrollbar>
        </Card>
    );
}

// ----------------------------------------------------------------------

type ItemProps = BoxProps & {
    item: IOrder;
};

function Item({ item, sx, ...other }: ItemProps) {
    return (
        <Box key={item.order_uuid} sx={{ gap: 2, display: 'flex', alignItems: 'center', justifyContent: "space-between", ...sx }} {...other}>
            <Typography variant='h5'>{item.order_number}</Typography>
            <Typography variant='h5' sx={{ opacity: 0.7 }}>{item.sub_total}</Typography>
            <Typography variant='h5' sx={{ opacity: 0.7 }}>{item.total_amount}</Typography>
        </Box>
    );
}
