import { LoadState } from "../../constants/enum";
import { IOrder, IOrderDiscountCoupon, IOrderItem, IOrderState } from "./orders.types";



export const defaultOrderItem: IOrderItem = {
    product_uuid: "",
    product_name: "",
    product_price: 0,
    tax_percentage: 0,
    quantity: 0,
    options: [],
    notes: "",
    is_served: false,
    product_price_by: "EACH",
    weight_per_unit: "",
    product_weight_unit: ""
}

export const defaultOrderDiscountCoupon: IOrderDiscountCoupon = {
    coupon_code: "",
    amount: "",
    is_expired: false,
    is_validated: false,
    is_valid: false
}
export const defaultOrder: IOrder = {
    order_uuid: null,
    order_number: "",
    table_uuid: null,
    table_number: null,
    menu_items: [],
    sub_total: 0,
    total_tax: 0,
    total_amount: 0,
    order_type: "TAKE_AWAY",
    payment_mode: "NONE",
    is_paid: 0,
    is_all_items_served: false,
    is_ready_to_serve: false,
    payment_received_uuid: null,
    discount_coupons: [defaultOrderDiscountCoupon],
    extra_charges: []
}


export const defaultOrderState: IOrderState = {
    orders_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_order: {
        loading: LoadState.idle,
        data: defaultOrder,
        error: null,
    }
}