import { createAsyncThunk } from "@reduxjs/toolkit"
import { IReceiptComposer } from "./receipt-composer.types"
import { axios_api } from "../../../api/axios-api"
import { IQueryParams, getSearchQuery } from "../../../common"
import { uploadSingleFileAsync } from "../../../helpers/uploadFilesAsync"
import { closeLoaderWithMessage, openLoaderWithMessage } from "../../common"



const GET_OUTLET_RECEIPTS_BASE_URL = "/receipt/get-receipt-composer"
const UPSERT_OUTLET_RECEIPTS_BASE_URL = "/receipt/upsert-receipt-composer"


export const fetchOutletReceiptsListAsync = createAsyncThunk<{ data: IReceiptComposer[], count: number }, IQueryParams>(
    'receipt-composer/fetchOutletReceiptsListAsync', async (queryParams: IQueryParams) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_OUTLET_RECEIPTS_BASE_URL}${searchQuery}`)
        return response.data as { data: IReceiptComposer[], count: number }
    },
)

export const fetchSingleOutletReceiptInfoAsync = createAsyncThunk<IReceiptComposer, string>(
    'receipt-composer/fetchSingleOutletReceiptInfoAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`${GET_OUTLET_RECEIPTS_BASE_URL}?receipt_composer_uuid=${uuid}`)
        return response.data.data[0]
    },
)


interface IUpsertSingleReceiptComposer extends IReceiptComposer {
    file: File | null,
}

export const upsertSingleOutletReceiptAsync = createAsyncThunk<IReceiptComposer, IUpsertSingleReceiptComposer>(
    'receipt-composer/upsertSingleOutletReceiptAsync', async (payload: IUpsertSingleReceiptComposer, thunkAPI) => {
        const { file, create_ts, insert_ts, ...restPayload } = payload
        thunkAPI.dispatch(openLoaderWithMessage("Saving Receipt Changes...!"))
        if (file) {
            const path = await uploadSingleFileAsync({
                file,
                moduleName: "RECEIPT",
                asPayload: {
                    receipt_composer_uuid: payload.receipt_composer_uuid
                }
            })
            restPayload.receipt_logo = path
        }

        const response = await axios_api.post(UPSERT_OUTLET_RECEIPTS_BASE_URL, restPayload)
        thunkAPI.dispatch(closeLoaderWithMessage())
        return response.data
    },
)
