import { ReservationsV2 } from "./ReservationsV2";
import { RESERVATIONS_V2_ROUTE } from "./ReservationsV2.constants";


export const reservationsV2Routes = [
    {
        path: RESERVATIONS_V2_ROUTE,
        exact: true,
        element: <ReservationsV2 />
    },
] 