import { axios_api } from "../api";

interface IUploadSingleFilePayload {
  file: File,
  moduleName: "ACCOUNTING" | "COMPANY" | "SHIFT" | "INVENTORY" | "RECEIPT" | "USER",
  asPayload: {
    [key: string]: string | number | null;
  },
}
export const uploadSingleFileAsync = async ({
  file,
  moduleName,
  asPayload
}: IUploadSingleFilePayload): Promise<string | null> => {
  // const { file_name, file_ext} = getFileNameAndExtension(file)
  const formdata = new FormData();
  formdata.append("files", file);
  formdata.append("module_name", moduleName);
  formdata.append("as_payload", JSON.stringify({
    ...asPayload,
    file_name: file.name.split(".")[0],
    file_ext: file.name.split(".")[1],
  }));
  const res = await axios_api.post("/general/upload-files", formdata);
  const data: string[] = res.data.data;
  return data.length > 0 ? data[0] : null
};

// export const uploadMultipleFiles = async (
//   files: IFileUpload[],
//   module_name: string,
//   asPayload: { [key: string]: string | number | null },
// ): Promise<string[]> => {
//   let existingFiles: string[] = [];
//   let noFileToUpload: boolean = true;
//   const formdata = new FormData();
//   for (const file of files) {
//     if (file.path) {
//       existingFiles.push(file.path);
//     } else {
//       noFileToUpload = false;
//       formdata.append("files", file.file as File); // appending every file to formdata
//     }
//   }

//   if (noFileToUpload) {
//     return existingFiles;
//   }

//   formdata.append("module_name", module_name);
//   formdata.append("as_payload", JSON.stringify(asPayload));
//   const result = await api.post("/general/upload-files", formdata);

//   return [...existingFiles].concat(result.data.data as string[]);
// };
