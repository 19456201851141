import React, { useEffect, useRef, useState } from 'react'
import { fetchSingleOrderEJSAsync, IFetchOrderEjs, IOrder } from '../../../../../../redux/orders';
import { Box, DialogTitle, IconButton } from '@mui/material';
import { Dialog } from '../../../../../../Components/Dialogs/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from '../../../../../../redux';
import { useReactToPrint } from 'react-to-print';


export interface IPrintOrderReceiptDialogProps {
    open: boolean;
    data: {
        order_uuid: string,
        outlet_uuid: string,
        order_type: IFetchOrderEjs["order_type"]
    }
    onClose: () => void;
}

export const PrintOrderReceiptDialog: React.FC<IPrintOrderReceiptDialogProps> = ({
    open,
    data,
    onClose
}) => {

    const dispatch = useAppDispatch()
    const { order_uuid, outlet_uuid, order_type } = data

    const contentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({ contentRef });
    const [currentOrderData, setCurrentOrderData] = useState("")

    useEffect(() => {
        fetchEjsData()
    }, [])

    const fetchEjsData = () => {
        dispatch(fetchSingleOrderEJSAsync({
            order_uuid, outlet_uuid, order_type, onCallback(isSuccess, data) {
                if (isSuccess && data) {
                    setCurrentOrderData(data)
                }
            }
        }))
    }

    return (
        <Dialog
            open={open}
            title="Payment"
            size="sm"
            contentWrappedWithForm={{ onSubmit: handlePrint }}
            onClose={onClose}
            contentSx={{
                padding: 0,
                minHeight: 550,
                background: "#171717"
            }}
            hideHeader
            actions={[
                {
                    type: 'button',
                    variant: "outlined",
                    label: "Close",
                    onClick: onClose
                },
                {
                    type: 'submit',
                    variant: "contained",
                    label: "Print",
                }
            ]}
        >
            <DialogTitle fontSize={18} fontWeight={700} sx={{ m: 0, p: 2, background: "#0f0f0f" }} id="customized-dialog-title">
                Order Receipt
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box ref={contentRef} sx={{ maxWidth: 288, color: "#000", background: "#fff" }} component="body" dangerouslySetInnerHTML={{ __html: currentOrderData }} />
            </Box>
        </Dialog>
    )
}
