import { LoadState } from "../../../constants/enum"
import { defaultProductIngredient } from "../products"
import { IProductCustomization, IProductCustomizationState, IProductModifier } from "./customization.types"

export const defaultProductModifier: IProductModifier = {
    modifier_uuid: null,
    modifier_name: "",
    product_uuid: "",
    customization_uuid: "",
    modifier_price_by: "EACH",
    modifier_price: 0,
    modifier_weight_unit: "",
    modifier_weight_per_unit: "",
    modifier_tax: 0,
    modifier_tax_amount: 0,
    modifier_description: "",
    modifier_type: "",
    modifier_ingredients: [],
    is_out_of_stock: false,
    out_of_stock_for: null,

    // Energy  Values
    calories: 0,
    kilojoules: 0,

    // Extra Details
    gst_number: "",
    external_id: "",
    external_data: "",

    status: "ACTIVE"
}

export const defaultProductCustomization: IProductCustomization = {
    customization_uuid: null,
    customization_name: "",
    customization_notes: "",
    customization_options: [],
    product_uuid: "",
    status: "ACTIVE"
}

export const defaultProductCustomizationState: IProductCustomizationState = {
    customizations_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_customization: {
        loading: LoadState.idle,
        data: defaultProductCustomization,
        error: null,
    },
    product_modifiers_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_product_modifier: {
        loading: LoadState.idle,
        data: defaultProductModifier,
        error: null,
    },
}