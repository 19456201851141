import React from 'react'
import { useAppDispatch } from "../../../../../../redux";
import { BackspaceRounded } from "@mui/icons-material";
import { Typography, Box, Button, Stack } from "@mui/material";
import Masonry from "react-responsive-masonry";
import { CustomRoundButton } from "../../../../../../Components/CustomButton";
import { defaultPaymentReceived, upsertSinglePaymentReceivedAsync } from "../../../../../../redux/accounting/payment-received";
import { useNavigate } from "react-router-dom";
import { RUPEE_SYMBOL } from "../../../../../../constants/constants";
import { isNotNull } from "../../../../../../helpers/isNotNull";
import { POS_ORDERS_ROUTE } from "../../../../MenuAndOrders.constants";
import { IOrder, upsertSingleOrderAsync, upsertSingleOrderWithCallbackAsync } from "../../../../../../redux/orders";
import { useFormik } from 'formik';


const dialNumbersArray: string[] = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    "0",
    "del",
];

export interface ICashPaymentScreenProps {
    orderInfo: IOrder;
    onSuccess: (data: IOrder) => void
}
export const CashPaymentScreen: React.FC<ICashPaymentScreenProps> = ({ orderInfo, onSuccess }) => {

    const [saveLoading, setSaveLoading] = React.useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [dialNumber, setDialNumber] = React.useState<string[]>([]);


    const handleDialPadNumbers = (number: string) => () => {
        setDialNumber((prev) => {
            const updatedDialNumber = number === "del" ? prev.slice(0, -1) : [...prev, number];
            return updatedDialNumber;
        });
    };


    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues
    } = useFormik({
        initialValues: {
            ...defaultPaymentReceived,
            order_type: orderInfo.order_type,
            order_number: orderInfo.order_number,
            total_tax: orderInfo.total_tax.toString(),
            total_amount: orderInfo.total_amount.toString(),
            order_details: orderInfo.menu_items,
            payment_mode: orderInfo.payment_mode,
        },
        validate: (values) => {
            const errors: any = {};
            // if (!values.category_name) {
            //     errors.category_name = "*This field is required.";
            // }
            return errors;
        },
        onSubmit: async (values) => {
            setSaveLoading(true);

            dispatch(upsertSingleOrderWithCallbackAsync({
                payload: orderInfo,
                onSuccess(isSuccess, data) {
                    if (isSuccess && data) {
                        dispatch(
                            upsertSinglePaymentReceivedAsync({
                                ...values,
                                order_status: "PAID"
                            })
                        ).then((res => {
                            if (res.meta.requestStatus === "fulfilled") {
                                onSuccess(data)
                            }
                        })).catch((error) => {
                            alert(error.message)
                        }).finally(() => {
                            setSaveLoading(false);
                        });
                    }
                },
            })).finally(() => {
                setSaveLoading(false);
            });


            // dispatch(
            //     upsertSingleOrderAsync(orderInfo)
            // ).then((res) => {
            //     if (res.meta.requestStatus === "fulfilled") {
            //         dispatch(
            //             upsertSinglePaymentReceivedAsync({
            //                 ...values,
            //                 order_status: "PAID"
            //             })
            //         ).then((res => {
            //             if (res.meta.requestStatus === "fulfilled") {
            //                 onSuccess()
            //                 // navigate(POS_ORDERS_ROUTE)
            //             }
            //         })).catch((error) => {
            //             alert(error.message)
            //         }).finally(() => {
            //             setSaveLoading(false);
            //         });
            //     }
            // }).finally(() => {
            //     setSaveLoading(false);
            // });


        },
    });


    React.useEffect(() => {
        const receivedAmount = dialNumber.join("")
        const changeAmount = (Number(receivedAmount) - Number(values.total_amount)).toFixed(2).toString()
        setValues({
            ...values,
            cash_received: receivedAmount,
            cash_changed: receivedAmount.length > 0 ? changeAmount : "0"
        })
    }, [dialNumber])

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
            }}
            component={"form"}
            onSubmit={handleSubmit}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                <Typography variant="h4" fontWeight={"700"}>
                    {`Total Amount : ${RUPEE_SYMBOL}${values.total_amount}`}
                </Typography>
                <Typography variant="h4" fontWeight={"700"}>
                    {`Change Amount :${RUPEE_SYMBOL}${values.cash_changed}`}
                </Typography>
            </Stack>
            <Typography variant="h2" fontWeight={"700"}>
                Received Amount
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                    color: "#fff",
                    gap: 1,
                }}
            >
                <Typography variant="h1" letterSpacing={0.5}
                    sx={{
                        border: "2px solid #8e8e8e",
                        padding: "2px 29px",
                        borderRadius: 2,
                        minWidth: 250,
                    }}>
                    {`${RUPEE_SYMBOL} ${isNotNull(values.cash_received)}`}
                </Typography>
            </Box>

            <Masonry columnsCount={3} gutter="12px" style={{ width: "50%" }}>
                {dialNumbersArray.map((number, index) => {
                    return (
                        <Button
                            key={index}
                            sx={{
                                padding: "10px",
                                margin: "5px",
                                fontSize: "1.2rem",
                                bgcolor: "#444242",
                                width: "100%",
                                height: "100%",
                            }}
                            onClick={handleDialPadNumbers(number)}
                        >
                            {number === "del" ? <BackspaceRounded /> : number}
                        </Button>
                    );
                })}
            </Masonry>


            <Stack direction="row" width="100%" spacing={2} px={2}>
                {/* <CustomButton
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
            >

            </CustomButton> */}
                <CustomRoundButton
                    title="Complete Order"
                    type="submit"
                    disabled={Number(values.cash_received) < Number(values.total_amount) || saveLoading}
                    fullWidth />
            </Stack>
        </Box>
    )
}
